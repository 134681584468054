@charset "UTF-8";
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: none;
}
b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  display: inline-block;
  font-size: 80%;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
select,
textarea {
  font: inherit;
}
optgroup {
  font-weight: 700;
}
button,
input,
select {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer;
}
[disabled] {
  cursor: default;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
*,
:after,
:before,
html {
  box-sizing: border-box;
}
html {
  -webkit-tap-highlight-color: transparent;
  background-attachment: fixed;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777;
  scroll-behavior: smooth;
}
.xdebug-var-dump {
  position: relative;
  z-index: 1000;
}
hr {
  border-width: 0;
  border-top: 1px solid;
  margin: 15px 0;
  opacity: 0.1;
}
img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
figure {
  margin: 0;
}
pre {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  overflow-x: auto;
  padding: 20px;
  width: 100%;
}
p:empty {
  display: none;
}
a,
button,
input {
  touch-action: manipulation;
}
ins {
  text-decoration: none;
}
embed,
iframe,
object {
  max-width: 100%;
}
.col,
.columns,
.gallery-item {
  margin: 0;
  padding: 0 15px 30px;
  position: relative;
  width: 100%;
}
.col-fit {
  flex: 1;
}
.col-first {
  order: -1;
}
.col-last {
  order: 1;
}
.col-inner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.col-has-radius {
  overflow: hidden;
}
@media screen and (min-width: 850px) {
  .col:first-child .col-inner {
    margin-left: auto;
    margin-right: 0;
  }
  .col + .col .col-inner {
    margin-left: 0;
    margin-right: auto;
  }
}
@media screen and (max-width: 549px) {
  .small-col-first {
    order: -1;
  }
}
@media screen and (min-width: 850px) {
  .large-col-first {
    order: -1;
  }
}
@media screen and (max-width: 849px) {
  .medium-col-first {
    order: -1;
  }
  .col {
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 850px) {
  .row-divided > .col + .col:not(.large-12) {
    border-left: 1px solid #ececec;
  }
  .row-divided.row-reverse > .col + .col:not(.large-12) {
    border-left: 0;
    border-right: 1px solid #ececec;
  }
  .col-divided {
    border-right: 1px solid #ececec;
    padding-right: 30px;
  }
  .col.col-divided:not(.col-first):last-child {
    border-left: 1px solid #ececec;
    border-right: 0;
    padding-left: 30px;
    padding-right: 0;
  }
  .col-border {
    border-right: 1px solid #ececec;
    padding-right: 0;
  }
  .col-border + .col,
  .col-divided + .col {
    padding-left: 30px;
  }
}
.dark .col-divided {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.align-equal > .col {
  display: flex;
}
.align-middle {
  align-items: center !important;
  align-self: center !important;
  vertical-align: middle !important;
}
.align-bottom {
  align-items: flex-end !important;
  align-self: flex-end !important;
  justify-content: flex-end !important;
  vertical-align: bottom !important;
}
.align-top {
  align-items: flex-start !important;
  align-self: flex-start !important;
  justify-content: flex-start !important;
  vertical-align: top !important;
}
.align-center {
  justify-content: center !important;
}
.align-right {
  justify-content: flex-end !important;
}
.small-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}
.small-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}
.small-3 {
  flex-basis: 25%;
  max-width: 25%;
}
.small-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}
.small-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}
.small-6 {
  flex-basis: 50%;
  max-width: 50%;
}
.small-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}
.small-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}
.small-9 {
  flex-basis: 75%;
  max-width: 75%;
}
.small-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}
.small-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}
.small-12,
.small-columns-1 .flickity-slider > .col,
.small-columns-1 > .col {
  flex-basis: 100%;
  max-width: 100%;
}
.small-columns-2 .flickity-slider > .col,
.small-columns-2 > .col {
  flex-basis: 50%;
  max-width: 50%;
}
.small-columns-3 .flickity-slider > .col,
.small-columns-3 > .col {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}
.small-columns-4 .flickity-slider > .col,
.small-columns-4 > .col {
  flex-basis: 25%;
  max-width: 25%;
}
.small-columns-5 .flickity-slider > .col,
.small-columns-5 > .col {
  flex-basis: 20%;
  max-width: 20%;
}
.small-columns-6 .flickity-slider > .col,
.small-columns-6 > .col {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}
.small-columns-7 .flickity-slider > .col,
.small-columns-7 > .col {
  flex-basis: 14.2857142857%;
  max-width: 14.2857142857%;
}
.small-columns-8 .flickity-slider > .col,
.small-columns-8 > .col {
  flex-basis: 12.5%;
  max-width: 12.5%;
}
@media screen and (min-width: 550px) {
  .medium-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .medium-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .medium-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .medium-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-12,
  .medium-columns-1 .flickity-slider > .col,
  .medium-columns-1 > .col {
    flex-basis: 100%;
    max-width: 100%;
  }
  .medium-columns-2 .flickity-slider > .col,
  .medium-columns-2 > .col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .medium-columns-3 .flickity-slider > .col,
  .medium-columns-3 > .col {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-columns-4 .flickity-slider > .col,
  .medium-columns-4 > .col {
    flex-basis: 25%;
    max-width: 25%;
  }
  .medium-columns-5 .flickity-slider > .col,
  .medium-columns-5 > .col {
    flex-basis: 20%;
    max-width: 20%;
  }
  .medium-columns-6 .flickity-slider > .col,
  .medium-columns-6 > .col {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-columns-7 .flickity-slider > .col,
  .medium-columns-7 > .col {
    flex-basis: 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-columns-8 .flickity-slider > .col,
  .medium-columns-8 > .col {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 850px) {
  .large-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .large-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .large-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .large-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .gallery-columns-1 .gallery-item,
  .large-12,
  .large-columns-1 .flickity-slider > .col,
  .large-columns-1 > .col {
    flex-basis: 100%;
    max-width: 100%;
  }
  .gallery-columns-2 .gallery-item,
  .large-columns-2 .flickity-slider > .col,
  .large-columns-2 > .col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .gallery-columns-3 .gallery-item,
  .large-columns-3 .flickity-slider > .col,
  .large-columns-3 > .col {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .gallery-columns-4 .gallery-item,
  .large-columns-4 .flickity-slider > .col,
  .large-columns-4 > .col {
    flex-basis: 25%;
    max-width: 25%;
  }
  .gallery-columns-5 .gallery-item,
  .large-columns-5 .flickity-slider > .col,
  .large-columns-5 > .col {
    flex-basis: 20%;
    max-width: 20%;
  }
  .gallery-columns-6 .gallery-item,
  .large-columns-6 .flickity-slider > .col,
  .large-columns-6 > .col {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .gallery-columns-7 .gallery-item,
  .large-columns-7 .flickity-slider > .col,
  .large-columns-7 > .col {
    flex-basis: 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .gallery-columns-8 .gallery-item,
  .large-columns-8 .flickity-slider > .col,
  .large-columns-8 > .col {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
}
.has-shadow > .col > .col-inner {
  background-color: #fff;
}
.col-hover-blur .col-inner,
.col-hover-fade .col-inner,
.col-hover-focus .col-inner {
  transition: all, 0.2s;
}
.col-hover-fade .col-inner {
  opacity: 0.6;
}
.col-hover-fade:hover .col-inner {
  opacity: 1;
}
.col-hover-blur .col-inner {
  filter: blur(3px);
}
.col-hover-blur:hover .col-inner {
  filter: blur(0);
}
.col-hover-focus:hover .col-inner {
  opacity: 1;
}
.row:hover .col-hover-focus .col:not(:hover) {
  opacity: 0.6;
}
.container,
.container-width,
.full-width .ubermenu-nav,
.row,
body {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.container,
.container-width,
.full-width .ubermenu-nav {
  max-width: 1080px;
}
.row.row-collapse {
  max-width: 1050px;
}
.row.row-small {
  max-width: 1065px;
}
.row.row-large {
  max-width: 1110px;
}
body.boxed,
body.boxed .header-wrapper,
body.boxed .is-sticky-section,
body.boxed header,
body.framed,
body.framed .header-wrapper,
body.framed header {
  max-width: 1170px;
}
@media screen and (min-width: 850px) {
  body.framed {
    margin: 30px auto;
  }
}
.flex-row {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
.flex-row-start {
  justify-content: flex-start;
}
.flex-row-center {
  justify-content: center;
}
.flex-row-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text-center .flex-row {
  justify-content: center;
}
.header .flex-row {
  height: 100%;
}
.flex-col {
  max-height: 100%;
}
.flex-grow {
  -ms-flex-negative: 1;
  -ms-flex-preferred-size: auto !important;
  flex: 1;
}
.flex-center {
  margin: 0 auto;
}
.flex-left {
  margin-right: auto;
}
.flex-right {
  margin-left: auto;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-has-center > .flex-col:not(.flex-center),
.flex-has-center > .flex-row > .flex-col:not(.flex-center),
.flex-has-center > .nav-row > .flex-col:not(.flex-center) {
  flex: 1;
}
@media (max-width: 849px) {
  .medium-flex-wrap {
    flex-flow: column wrap;
  }
  .medium-flex-wrap .flex-col {
    flex-grow: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
  }
}
@media (max-width: 549px) {
  .small-flex-wrap {
    flex-flow: column wrap;
  }
  .small-flex-wrap .flex-col {
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 850px) {
  .col2-set,
  .u-columns {
    display: flex;
  }
  .col2-set > div + div,
  .u-columns > div + div {
    padding-left: 30px;
  }
}
.row-grid .box .image-cover {
  height: 100%;
  padding: 0;
}
.grid-col > .col-inner {
  height: 100%;
}
.grid-col .banner,
.grid-col .box,
.grid-col .box-image,
.grid-col .box-image img,
.grid-col .col-inner > .img,
.grid-col .flickity-slider > .img,
.grid-col .image-cover,
.grid-col .image-cover img,
.grid-col .slider,
.grid-col .slider-wrapper,
.grid-col .slider:not(.flickity-enabled),
.grid-col .slider > .img,
.grid-col > .col-inner > .img,
.grid-col > .col-inner > .img .img-inner > img,
.grid-col > .col-inner > .img div {
  bottom: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0 !important;
  position: absolute !important;
  top: 0;
  width: 100%;
}
.grid-col .box-image img {
  font-family: "object-fit: cover;";
}
.grid-col .flickity-viewport {
  height: 100% !important;
}
.grid-col .box:not(.box-shade):not(.box-overlay):not(.box-badge) .box-text {
  background-color: hsla(0, 0%, 100%, 0.95);
  bottom: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
  position: absolute;
}
@media (prefers-reduced-motion) {
  .grid-col {
    transition: none !important;
  }
}
@media (-ms-high-contrast: none) {
  .payment-icon svg {
    max-width: 50px;
  }
  .slider-nav-circle .flickity-prev-next-button svg {
    height: 36px !important;
  }
  .nav > li > a > i {
    top: -1px;
  }
}
.gallery,
.row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.row > div:not(.col):not([class^="col-"]):not([class*=" col-"]) {
  width: 100% !important;
}
.row.row-grid,
.row.row-masonry {
  display: block;
}
.row.row-grid > .col,
.row.row-masonry > .col {
  float: left;
}
.container .row:not(.row-collapse),
.lightbox-content .row:not(.row-collapse),
.row .gallery,
.row .row:not(.row-collapse) {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0;
  padding-right: 0;
  width: auto;
}
.row .container {
  padding-left: 0;
  padding-right: 0;
}
.banner + .row:not(.row-collapse),
.banner-grid-wrapper + .row:not(.row-collapse),
.section + .row:not(.row-collapse),
.slider-wrapper + .row:not(.row-collapse) {
  margin-top: 30px;
}
.row-full-width {
  max-width: 100% !important;
}
.row-isotope {
  transition: height 0.3s;
}
.row-reverse {
  flex-direction: row-reverse;
}
@media screen and (max-width: 549px) {
  .small-row-reverse {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 849px) {
  .medium-row-reverse {
    flex-direction: row-reverse;
  }
}
.row-collapse {
  padding: 0;
}
.row-collapse > .col,
.row-collapse > .flickity-viewport > .flickity-slider > .col {
  padding: 0 !important;
}
.row-collapse > .col .container {
  max-width: 100%;
}
.container .row-small:not(.row-collapse),
.row .row-small:not(.row-collapse) {
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.row-small > .col,
.row-small > .flickity-viewport > .flickity-slider > .col {
  margin-bottom: 0;
  padding: 0 9.8px 19.6px;
}
.container .row-xsmall:not(.row-collapse),
.row .row-xsmall:not(.row-collapse) {
  margin-bottom: 0;
  margin-left: -2px;
  margin-right: -2px;
}
.row-xsmall > .col,
.row-xsmall > .flickity-viewport > .flickity-slider > .col {
  margin-bottom: 0;
  padding: 0 2px 3px;
}
@media screen and (min-width: 850px) {
  .row-large {
    padding-left: 0;
    padding-right: 0;
  }
  .container .row-large:not(.row-collapse),
  .row .row-large:not(.row-collapse) {
    margin-bottom: 0;
    margin-left: -30px;
    margin-right: -30px;
  }
  .row-large > .col,
  .row-large > .flickity-viewport > .flickity-slider > .col {
    margin-bottom: 0;
    padding: 0 30px 30px;
  }
}
.row-dashed,
.row-solid {
  overflow: hidden;
}
.row-dashed .col,
.row-solid .col {
  padding: 0;
  position: relative;
}
.row-dashed .col-inner,
.row-solid .col-inner {
  padding: 15px;
}
.row-dashed .col:before,
.row-solid .col:before {
  border-left: 1px dashed #ddd;
  content: " ";
  height: 100%;
  left: -1px;
  position: absolute;
  top: 0;
}
.row-dashed .col:after,
.row-solid .col:after {
  border-bottom: 1px dashed #ddd;
  bottom: -1px;
  content: " ";
  height: 0;
  left: 0;
  position: absolute;
  top: auto;
  width: 100%;
}
.row-solid .col:before {
  border-left-style: solid;
}
.row-solid .col:after {
  border-bottom-style: solid;
}
.dark .row-dashed .col:after,
.dark .row-dashed .col:before,
.dark .row-solid .col:after,
.dark .row-solid .col:before {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.block-html-after-header .row .col {
  padding-bottom: 0;
}
.wpb-js-composer .row:before {
  display: block !important;
}
.section {
  align-items: center;
  display: flex;
  flex-flow: row;
  min-height: auto;
  padding: 30px 0;
  position: relative;
  width: 100%;
}
.section.dark {
  background-color: #666;
}
.section-bg {
  overflow: hidden;
}
.section-bg,
.section-content {
  width: 100%;
}
.section-content {
  z-index: 1;
}
.has-parallax {
  overflow: hidden;
}
.container .section,
.row .section {
  padding-left: 30px;
  padding-right: 30px;
}
.is-sticky-section + .sticky-section-helper {
  background-color: #000;
  min-height: 100vh !important;
}
.sticky-section-helper + div {
  background-color: #fff;
  box-shadow: 15px 15px 15px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}
.sticky-section {
  background-color: #fff;
  height: 100% !important;
  height: 100vh !important;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
  right: 0;
  top: 0;
  width: 100% !important;
}
.is-sticky-section {
  position: fixed !important;
}
.nav-dropdown {
  background-color: #fff;
  color: #777;
  display: table;
  left: -99999px;
  margin: 0;
  max-height: 0;
  min-width: 260px;
  opacity: 0;
  padding: 20px 0;
  position: absolute;
  text-align: left;
  transition: opacity 0.25s, visibility 0.25s;
  visibility: hidden;
  z-index: 9;
}
.dark.nav-dropdown {
  color: #f1f1f1;
}
.nav-dropdown:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}
.nav-dropdown li {
  display: block;
  margin: 0;
  vertical-align: top;
}
.nav-dropdown li ul {
  margin: 0;
  padding: 0;
}
.nav-dropdown > li.html {
  min-width: 260px;
}
.next-prev-thumbs li.has-dropdown:hover > .nav-dropdown,
.no-js li.has-dropdown:hover > .nav-dropdown,
li.current-dropdown > .nav-dropdown {
  left: -15px;
  max-height: inherit;
  opacity: 1;
  visibility: visible;
}
.nav-right li.current-dropdown:last-child > .nav-dropdown,
.nav-right li.has-dropdown:hover:last-child > .nav-dropdown {
  left: auto;
  right: -15px;
}
.nav-column li > a,
.nav-dropdown > li > a {
  display: block;
  line-height: 1.3;
  padding: 10px 20px;
  width: auto;
}
.nav-column > li:last-child:not(.nav-dropdown-col) > a,
.nav-dropdown > li:last-child:not(.nav-dropdown-col) > a {
  border-bottom: 0 !important;
}
.dropdown-uppercase.nav-dropdown .nav-column > li > a,
.nav-dropdown.dropdown-uppercase > li > a {
  font-size: 0.85em;
  text-transform: uppercase;
}
.nav-dropdown > li.image-column,
.nav-dropdown > li.nav-dropdown-col {
  display: table-cell;
  min-width: 160px;
  text-align: left;
  white-space: nowrap;
  width: 160px;
}
.nav-dropdown > li.image-column:not(:last-child),
.nav-dropdown > li.nav-dropdown-col:not(:last-child) {
  border-right: 1px solid transparent;
}
.nav-dropdown .menu-item-has-children > a,
.nav-dropdown .nav-dropdown-col > a,
.nav-dropdown .title > a {
  color: #000;
  font-size: 0.8em;
  font-weight: bolder;
  text-transform: uppercase;
}
.nav-dropdown .nav-dropdown-col .menu-item-has-children {
  margin-top: 5px;
}
.nav-dropdown li.image-column {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.nav-dropdown li.image-column > a {
  font-size: 0 !important;
  line-height: 0 !important;
  margin: -20px 0 !important;
  padding: 0 !important;
}
.nav-dropdown li.image-column > a img {
  display: block;
  margin: 0;
  width: 100%;
}
.nav-dropdown li.image-column > a:hover {
  opacity: 0.8;
}
.nav-dropdown-default li.image-column:first-child > a {
  margin-left: -20px !important;
  margin-right: 20px !important;
}
.nav-dropdown-default li.image-column:last-child > a {
  margin-right: -20px !important;
}
.nav-dropdown.nav-dropdown-bold > li.nav-dropdown-col,
.nav-dropdown.nav-dropdown-simple > li.nav-dropdown-col {
  border-color: #f1f1f1;
}
.nav-dropdown-default .nav-column li > a,
.nav-dropdown.nav-dropdown-default > li > a {
  border-bottom: 1px solid #ececec;
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
}
.nav-dropdown-default {
  padding: 20px;
}
.nav-dropdown-bold .nav-column li > a,
.nav-dropdown.nav-dropdown-bold > li > a {
  border-radius: 10px;
  margin: 0 10px;
  padding-left: 10px;
  padding-right: 5px;
}
.nav-dropdown-bold .nav-column li > a:hover,
.nav-dropdown-bold.dark .nav-column li > a:hover,
.nav-dropdown.nav-dropdown-bold.dark > li > a:hover,
.nav-dropdown.nav-dropdown-bold > li > a:hover {
  background-color: #446084;
  color: #fff !important;
}
.nav-dropdown-simple .nav-column li > a:hover,
.nav-dropdown.nav-dropdown-simple > li > a:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.nav-dropdown.nav-dropdown-bold li.html,
.nav-dropdown.nav-dropdown-simple li.html {
  padding: 0 20px 10px;
}
.menu-item-has-block .nav-dropdown {
  padding: 0;
}
li.current-dropdown > .nav-dropdown-full,
li.has-dropdown:hover > .nav-dropdown-full {
  display: flex;
  flex-wrap: wrap;
  left: 50% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 1150px !important;
  position: fixed;
  right: auto;
  transform: translateX(-50%);
  transition: opacity 0.3s !important;
  width: 100%;
}
.nav-dropdown-full > li.nav-dropdown-col {
  white-space: normal;
}
.nav-dropdown-has-arrow li.has-dropdown:after,
.nav-dropdown-has-arrow li.has-dropdown:before {
  border: solid transparent;
  bottom: -2px;
  content: "";
  height: 0;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
  z-index: 10;
}
.nav-dropdown-has-arrow li.has-dropdown:after {
  border-color: hsla(0, 0%, 87%, 0) hsla(0, 0%, 87%, 0) #fff;
  border-width: 8px;
  margin-left: -8px;
}
.nav-dropdown-has-arrow li.has-dropdown:before {
  border-width: 11px;
  margin-left: -11px;
  z-index: -999;
}
.nav-dropdown-has-arrow
  .ux-nav-vertical-menu
  .menu-item-design-custom-size:after,
.nav-dropdown-has-arrow
  .ux-nav-vertical-menu
  .menu-item-design-custom-size:before,
.nav-dropdown-has-arrow .ux-nav-vertical-menu .menu-item-design-default:after,
.nav-dropdown-has-arrow .ux-nav-vertical-menu .menu-item-design-default:before,
.nav-dropdown-has-arrow li.has-dropdown.menu-item-design-container-width:after,
.nav-dropdown-has-arrow li.has-dropdown.menu-item-design-container-width:before,
.nav-dropdown-has-arrow li.has-dropdown.menu-item-design-full-width:after,
.nav-dropdown-has-arrow li.has-dropdown.menu-item-design-full-width:before {
  display: none;
}
.nav-dropdown-has-shadow .nav-dropdown {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
}
.nav-dropdown-has-arrow.nav-dropdown-has-border li.has-dropdown:before {
  border-bottom-color: #ddd;
}
.nav-dropdown-has-border .nav-dropdown {
  border: 2px solid #ddd;
}
.nav-dropdown-has-arrow li.current-dropdown.has-dropdown:after,
.nav-dropdown-has-arrow li.current-dropdown.has-dropdown:before {
  opacity: 1;
  transition: opacity 0.25s;
}
.label-hot.menu-item > a:after,
.label-new.menu-item > a:after,
.label-popular.menu-item > a:after,
.label-sale.menu-item > a:after {
  background-color: #000;
  border-radius: 2px;
  color: #fff;
  content: "";
  display: inline-block;
  font-size: 9px;
  font-weight: bolder;
  letter-spacing: -0.1px;
  line-height: 9px;
  margin-left: 4px;
  padding: 2px 3px 3px;
  position: relative;
  text-transform: uppercase;
  top: -2px;
}
.label-new.menu-item > a:after {
  background-color: #446084;
}
.label-sale.menu-item > a:after {
  background-color: #d26e4b;
}
.label-popular.menu-item > a:after {
  background-color: #7a9c59;
}
.label-hot.menu-item > a:after {
  background-color: #b20000;
}
.nav p {
  margin: 0;
  padding-bottom: 0;
}
.nav,
.nav ul:not(.nav-dropdown) {
  margin: 0;
  padding: 0;
}
.nav > li > a.button,
.nav > li > button {
  margin-bottom: 0;
}
.nav {
  align-items: center;
  display: inline-block;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.nav,
.nav > li {
  position: relative;
}
.nav > li {
  list-style: none;
  margin: 0 7px;
  padding: 0;
  transition: background-color 0.3s;
}
.nav > li,
.nav > li > a {
  display: inline-block;
}
.nav > li > a {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.html .nav > li > a {
  padding-bottom: 0;
  padding-top: 0;
}
.nav-small .nav > li > a,
.nav.nav-small > li > a {
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: top;
}
.nav-small.nav > li.html {
  font-size: 0.75em;
}
.nav-center {
  justify-content: center;
}
.nav-fill {
  justify-content: space-between;
}
.nav-left {
  justify-content: flex-start;
}
.nav-right {
  justify-content: flex-end;
}
@media (max-width: 849px) {
  .medium-nav-center {
    justify-content: center;
  }
}
@media (max-width: 549px) {
  .small-nav-center {
    justify-content: center;
  }
}
.nav-column > li > a,
.nav-dropdown > li > a,
.nav-vertical-fly-out > li > a,
.nav > li > a {
  color: hsla(0, 0%, 40%, 0.85);
  transition: all 0.2s;
}
.nav-column > li > a,
.nav-dropdown > li > a {
  display: block;
}
.nav-column li.active > a,
.nav-column li > a:hover,
.nav-dropdown li.active > a,
.nav-dropdown > li > a:hover,
.nav-vertical-fly-out > li > a:hover,
.nav > li.active > a,
.nav > li.current > a,
.nav > li > a.active,
.nav > li > a.current,
.nav > li > a:hover {
  color: hsla(0, 0%, 7%, 0.85);
}
.nav li:first-child {
  margin-left: 0 !important;
}
.nav li:last-child {
  margin-right: 0 !important;
}
.nav-uppercase > li > a {
  font-weight: bolder;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.nav-thin > li > a {
  font-weight: thin !important;
}
@media (min-width: 850px) {
  .nav-divided > li {
    margin: 0 0.7em;
  }
  .nav-divided > li + li > a:after {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    content: "";
    height: 15px;
    left: -1em;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    width: 1px;
  }
  .nav-divided > li + li.icon-top > a:after {
    height: 50px;
    margin-top: -25px;
  }
}
li.html .row,
li.html form,
li.html input,
li.html select {
  margin: 0;
}
li.html > .button {
  margin-bottom: 0;
}
li.hide-title > a {
  display: none !important;
}
.nav-pagination > li > a,
.nav-pagination > li > span {
  border: 2px solid;
  border-radius: 99px;
  display: block;
  font-size: 1.1em;
  font-weight: bolder;
  height: 2.25em;
  line-height: 2em;
  min-width: 2.25em;
  padding: 0 7px;
  text-align: center;
  transition: all 0.3s;
  vertical-align: top;
  width: auto;
}
.nav-pagination > li i {
  font-size: 1.2em;
  top: -1px;
  vertical-align: middle;
}
.nav-pagination > li > .current,
.nav-pagination > li > a:hover,
.nav-pagination > li > span:hover {
  background-color: #446084;
  border-color: #446084;
  color: #fff;
}
.off-canvas .mobile-sidebar-slide {
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
.off-canvas .mobile-sidebar-slide .sidebar-menu {
  height: 100%;
  padding-bottom: 0;
  padding-top: 0;
  transform: translateX(0);
  transition: transform 0.3s;
  will-change: transform;
}
.mobile-sidebar-levels-1 .nav-slide > li > .sub-menu,
.mobile-sidebar-levels-1 .nav-slide > li > ul.children {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  display: none;
  left: 100%;
  opacity: 1;
  overflow: visible;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 0;
  padding-top: 20px;
  position: fixed;
  top: 0;
  transform: none;
  width: 100%;
}
.mobile-sidebar-levels-1 .nav-slide > li > .sub-menu > li,
.mobile-sidebar-levels-1 .nav-slide > li > ul.children > li {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.mobile-sidebar-levels-1 .nav-slide > li > .sub-menu.is-current-slide,
.mobile-sidebar-levels-1 .nav-slide > li > ul.children.is-current-slide {
  display: block;
}
.mobile-sidebar-levels-1 .nav-slide > li > .sub-menu.is-current-parent,
.mobile-sidebar-levels-1 .nav-slide > li > ul.children.is-current-parent {
  overflow-y: hidden;
  padding-right: 100%;
  width: 200%;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header),
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header) {
  border-top: 1px solid #ececec;
  margin-bottom: 0;
  margin-top: 0;
  transition: background-color 0.3s;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > a,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > a {
  color: hsla(0, 0%, 40%, 0.85);
  font-size: 0.8em;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-top: 15px;
  text-transform: uppercase;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header).active,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header):hover,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header).active,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header):hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > .sub-menu,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > ul.children,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > .sub-menu,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > ul.children {
  -webkit-overflow-scrolling: touch;
  bottom: 0;
  display: none;
  left: 200%;
  opacity: 1;
  overflow: visible;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 0;
  padding-top: 20px;
  position: fixed;
  top: 0;
  transform: none;
  width: 100%;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > .sub-menu
  > li,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > ul.children
  > li,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > .sub-menu
  > li,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > ul.children
  > li {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > .sub-menu.is-current-slide,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > ul.children.is-current-slide,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > .sub-menu.is-current-slide,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > ul.children.is-current-slide {
  display: block;
}
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > .sub-menu.is-current-parent,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > .sub-menu
  > li:not(.nav-slide-header)
  > ul.children.is-current-parent,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > .sub-menu.is-current-parent,
.mobile-sidebar-levels-2
  .nav-slide
  > li
  > ul.children
  > li:not(.nav-slide-header)
  > ul.children.is-current-parent {
  overflow-y: hidden;
  padding-right: 100%;
  width: 200%;
}
.nav-slide {
  -webkit-overflow-scrolling: auto;
  height: 100%;
  overflow: visible;
  overflow-y: auto;
  padding-top: 20px;
}
.nav-slide .active > .toggle {
  transform: none;
}
.nav-slide > li {
  flex-shrink: 0;
}
.nav-slide-header .toggle {
  margin-left: 0 !important;
  padding-left: 5px;
  position: relative !important;
  text-align: left;
  top: 0 !important;
  width: 100%;
}
.nav-slide-header .toggle i {
  font-size: 1.4em;
  margin-right: 2px;
}
.text-center .nav-slide-header .toggle {
  margin-left: auto !important;
  margin-right: auto !important;
  width: auto;
}
.nav-line-bottom > li > a:before,
.nav-line-grow > li > a:before,
.nav-line > li > a:before {
  background-color: #446084;
  content: " ";
  height: 3px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: all 0.3s;
  width: 100%;
}
.nav-line-bottom > li.active > a:before,
.nav-line-bottom > li:hover > a:before,
.nav-line-grow > li.active > a:before,
.nav-line-grow > li:hover > a:before,
.nav-line > li.active > a:before,
.nav-line > li:hover > a:before {
  opacity: 1;
}
.nav:hover > li:not(:hover) > a:before {
  opacity: 0;
}
.tabbed-content .nav-line-bottom:before {
  bottom: 0;
  top: auto;
}
.nav-line-grow > li > a:before {
  width: 0;
}
.nav-line-grow > li.active > a:before,
.nav-line-grow > li:hover > a:before {
  width: 100%;
}
.nav-line-bottom > li:after,
.nav-line-bottom > li:before {
  display: none;
}
.nav-line-bottom > li > a:before {
  bottom: 0;
  top: auto;
}
.nav-box > li.active > a,
.nav-pills > li.active > a {
  background-color: #446084;
  color: #fff;
  opacity: 1;
}
.nav-tabs > li.active > a {
  background-color: #fff;
}
.nav-dark .nav.nav-tabs > li.active > a {
  color: #000;
}
.nav-outline > li.active > a {
  border-color: currentColor;
  color: #446084;
}
.tabbed-content {
  display: flex;
  flex-flow: row wrap;
}
.tabbed-content .nav,
.tabbed-content .tab-panels {
  width: 100%;
}
.tab-panels {
  padding-top: 1em;
}
.tab-panels .entry-content {
  padding: 0;
}
.tab-panels .panel:not(.active) {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  visibility: hidden;
}
.tab-panels .panel.active {
  display: block !important;
}
.nav-box > li,
.nav-outline > li,
.nav-pills > li,
.nav-tabs > li {
  margin: 0;
}
.nav-box > li > a,
.nav-pills > li > a {
  line-height: 2.5em;
  padding: 0 0.75em;
}
.nav-pills > li > a {
  border-radius: 99px;
}
.nav-tabs > li > a {
  background-color: rgba(0, 0, 0, 0.04);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 2px solid #ddd;
  padding-left: 15px;
  padding-right: 15px;
}
.nav-tabs > li.active > a {
  border-top-color: #446084;
}
.nav-dark .nav-tabs > li:not(.active) > a {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: transparent;
}
.tabbed-content .nav-tabs > li {
  margin: 0 0.1em -1px;
}
.nav-tabs + .tab-panels {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 30px;
}
.nav-outline > li > a {
  border: 2px solid transparent;
  border-radius: 32px;
  line-height: 2.4em;
  padding: 0 0.75em;
  position: relative;
  z-index: 10;
}
@media (min-width: 850px) {
  .tabbed-content .nav-vertical {
    padding-top: 0;
    width: 25%;
  }
  .nav-vertical + .tab-panels {
    border-left: 1px solid #ddd;
    flex: 1;
    margin-top: 0 !important;
    padding: 0 30px 30px;
  }
  .tabbed-content .nav-vertical.nav-outline {
    padding-right: 15px;
  }
}
@media (max-width: 549px) {
  .small-nav-collapse > li {
    margin: 0 !important;
    width: 100%;
  }
  .small-nav-collapse > li a {
    display: block;
    width: 100%;
  }
  .small-nav-touch {
    -webkit-overflow-scrolling: touch;
    flex-flow: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .small-nav-touch > li {
    display: inline-block;
  }
}
.sidebar-menu-tabs__tab {
  background-color: rgba(0, 0, 0, 0.02);
  flex: 1 0 50%;
  list-style-type: none;
  max-width: 50%;
  text-align: center;
}
.sidebar-menu-tabs__tab.active {
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar-menu-tabs__tab-text {
  flex: 1 1 auto;
}
.sidebar-menu-tabs.nav > li {
  margin: 0;
}
.sidebar-menu-tabs.nav > li > a {
  display: flex;
  padding: 15px 10px;
}
.toggle {
  box-shadow: none !important;
  color: currentColor;
  height: auto;
  line-height: 1;
  margin: 0 5px 0 0;
  opacity: 0.6;
  padding: 0;
  width: 45px;
}
.toggle i {
  font-size: 1.8em;
  transition: all 0.3s;
}
.toggle:focus {
  color: currentColor;
}
.toggle:hover {
  cursor: pointer;
  opacity: 1;
}
.active > .toggle {
  transform: rotate(-180deg);
}
.active > ul.children,
.active > ul.sub-menu:not(.nav-dropdown) {
  display: block !important;
}
.widget .toggle {
  margin: 0 0 0 5px;
  min-height: 1em;
  width: 1em;
}
.widget .toggle i {
  font-size: 1.2em;
  top: 1px;
}
@media (prefers-reduced-motion) {
  .toggle {
    transition: none;
  }
}
.nav.nav-vertical {
  flex-flow: column;
}
.nav.nav-vertical li {
  list-style: none;
  margin: 0;
  width: 100%;
}
.nav-vertical li li {
  font-size: 1em;
  padding-left: 0.5em;
}
.nav-vertical .image-column {
  display: none !important;
}
.nav-vertical > li {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.nav-vertical > li ul {
  width: 100%;
}
.nav-vertical li li.menu-item-has-children {
  margin-bottom: 1em;
}
.nav-vertical li li.menu-item-has-children:not(:first-child) {
  margin-top: 1em;
}
.nav-vertical > li > a,
.nav-vertical > li > ul > li a {
  align-items: center;
  display: flex;
  flex-grow: 1;
  width: auto;
}
.nav-vertical li li.menu-item-has-children > a {
  color: #000;
  font-size: 0.8em;
  font-weight: bolder;
  text-transform: uppercase;
}
.nav-vertical > li.html {
  padding-bottom: 1em;
  padding-top: 1em;
}
.nav-vertical > li > ul li a {
  color: #666;
  transition: color 0.2s;
}
.nav-vertical > li > ul li a:hover {
  color: #111;
  opacity: 1;
}
.nav-vertical > li > ul {
  margin: 0 0 2em;
  padding-left: 1em;
}
.nav .children {
  left: -99999px;
  opacity: 0;
  position: fixed;
  transform: translateX(-10px);
  transition: background-color 0.6s, transform 0.3s, opacity 0.3s;
}
@media (prefers-reduced-motion) {
  .nav .children {
    opacity: 1;
    transform: none;
  }
}
.nav:not(.nav-slide) .active > .children {
  left: auto;
  opacity: 1;
  padding-bottom: 30px;
  position: inherit;
  transform: translateX(0);
}
.nav-sidebar.nav-vertical > li.menu-item.active,
.nav-sidebar.nav-vertical > li.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.nav-sidebar.nav-vertical > li + li {
  border-top: 1px solid #ececec;
}
.dark .nav-sidebar.nav-vertical > li + li,
.dark .nav-vertical-fly-out > li + li {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.nav-vertical > li + li {
  border-top: 1px solid #ececec;
}
.nav-vertical-fly-out > li + li {
  border-color: #ececec;
}
.nav-vertical.nav-line > li > a:before {
  height: 100%;
  left: auto;
  right: -2px;
  width: 3px;
  z-index: 1;
}
.nav-vertical.nav-tabs > li > a {
  border: 0;
}
.nav-vertical.nav-tabs > li.active > a {
  border-left: 3px solid #446084;
}
.text-center .toggle {
  margin-left: -35px;
}
.text-center .nav-vertical li {
  border: 0;
}
.text-center .nav-vertical li li,
.text-center.nav-vertical li li {
  padding-left: 0;
}
@media screen and (max-width: 549px) {
  .small-nav-vertical {
    flex-flow: column;
    margin-bottom: 20px;
  }
  .small-nav-vertical > li {
    border-bottom: 1px solid #ececec;
    list-style: none;
    margin: 0 !important;
    width: 100%;
  }
}
.nav-vertical .mega-menu-item {
  width: 100%;
}
.nav-vertical .header-button,
.nav-vertical .social-icons {
  display: block;
  width: 100%;
}
@media screen and (min-width: 849px) {
  .col-divided > .col-inner > .nav-wrapper > .nav-vertical {
    width: calc(100% + 31px);
  }
}
.header-vertical-menu__opener {
  align-items: center;
  background-color: #446084;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  font-weight: 600;
  height: 50px;
  justify-content: space-between;
  padding: 12px 15px;
  width: 250px;
}
.header-vertical-menu__opener .icon-menu {
  font-size: 1.7em;
  margin-right: 10px;
}
.header-vertical-menu__tagline {
  display: block;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.3;
}
.header-vertical-menu__title {
  flex: 1 1 auto;
}
.header-vertical-menu__fly-out {
  background-color: #fff;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 250px;
  z-index: 9;
}
.header-vertical-menu__fly-out .nav-vertical-fly-out {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.header-vertical-menu__fly-out .nav-vertical-fly-out > li.menu-item {
  list-style: none;
  margin: 0;
  position: static;
}
.header-vertical-menu__fly-out
  .nav-vertical-fly-out
  > li.menu-item[class*=" label-"]
  > a:after,
.header-vertical-menu__fly-out
  .nav-vertical-fly-out
  > li.menu-item[class^="label-"]
  > a:after {
  order: 2;
  top: 0;
}
.header-vertical-menu__fly-out
  .nav-vertical-fly-out
  > li.menu-item
  > a
  > i.icon-angle-down {
  margin-left: auto;
  margin-right: -0.1em;
  order: 3;
  transform: rotate(-90deg);
}
.header-vertical-menu__fly-out .nav-vertical-fly-out > li.menu-item > a {
  align-items: center;
  display: flex;
  padding: 10px 15px;
}
.header-vertical-menu__fly-out .nav-vertical-fly-out li.has-icon-left > a > i,
.header-vertical-menu__fly-out .nav-vertical-fly-out li.has-icon-left > a > img,
.header-vertical-menu__fly-out
  .nav-vertical-fly-out
  li.has-icon-left
  > a
  > svg {
  margin-right: 10px;
}
.header-vertical-menu__fly-out .nav-top-link {
  font-size: 0.9em;
  order: 1;
}
.header-vertical-menu__fly-out .current-dropdown .nav-dropdown {
  left: 100% !important;
  top: 0;
}
.header-vertical-menu__fly-out .current-dropdown.menu-item .nav-dropdown {
  min-height: 100%;
}
.header-vertical-menu__fly-out--open {
  opacity: 1;
  visibility: visible;
}
.sticky-hide-on-scroll--active .header-vertical-menu__fly-out,
.stuck .header-vertical-menu__fly-out {
  opacity: 0;
  visibility: hidden;
}
.header-wrapper:not(.stuck) .header-vertical-menu__fly-out {
  transition: opacity 0.25s, visibility 0.25s;
}
.header-vertical-menu__fly-out.has-shadow {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
}
.header-vertical-menu:hover .header-vertical-menu__fly-out {
  opacity: 1;
  visibility: visible;
}
.badge-container {
  margin: 30px 0 0;
}
.badge {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: table;
  height: 2.8em;
  pointer-events: none;
  width: 2.8em;
  z-index: 20;
}
.badge.top {
  left: 0;
}
.badge-inner {
  background-color: #446084;
  color: #fff;
  display: table-cell;
  font-weight: bolder;
  height: 100%;
  line-height: 0.85;
  padding: 2px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}
.badge-small {
  height: 2.3em;
  width: 2.3em;
}
.badge-small .badge-inner {
  line-height: 0.7;
}
.badge + .badge {
  height: 2.5em;
  opacity: 0.9;
  width: 2.5em;
}
.badge + .badge + .badge {
  opacity: 0.8;
}
.badge-frame .badge-inner,
.badge-outline .badge-inner {
  background-color: #fff;
  border: 2px solid;
  color: #446084;
}
.has-hover:hover .badge-outline .badge-inner {
  background-color: #446084;
  border-color: #446084;
  color: #fff;
}
.badge-circle,
.badge-outline {
  margin-left: -0.4em;
}
.badge-circle-inside {
  margin-left: 1em;
}
.badge-circle + .badge {
  margin-left: -0.4em;
  margin-top: -0.4em;
}
.badge-circle .badge-inner,
.badge-circle-inside .badge-inner {
  border-radius: 999px;
}
.badge-frame .badge-inner,
.widget .badge-outline .badge-inner {
  background-color: transparent;
}
.badge-frame {
  height: 2em;
  margin-left: -4px;
}
.widget .badge {
  margin: 0 !important;
}
.dark .widget .badge-outline .badge-inner {
  color: #fff;
  opacity: 0.7;
}
.row-collapse .badge-circle {
  margin-left: 15px;
}
.banner {
  background-color: #446084;
  min-height: 30px;
  position: relative;
  width: 100%;
}
.banner-bg {
  overflow: hidden;
}
.banner-layers {
  height: 100%;
  max-height: 100%;
  position: relative;
}
@media (max-width: 549px) {
  .banner-layers {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
}
.banner-layers > * {
  position: absolute !important;
}
.dark .banner a {
  color: #fff;
}
.video-bg {
  font-family: "object-fit: cover; object-position: center center;";
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  position: absolute;
  width: 100%;
}
@media (min-width: 549px) {
  .has-video .bg {
    display: none;
  }
}
.box {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.box > a {
  display: block;
  width: 100%;
}
.box a {
  display: inline-block;
}
.box,
.box-image,
.box-text {
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
}
.box-image > a,
.box-image > div > a {
  display: block;
}
.box-image > a:focus-visible,
.box-image > div > a:focus-visible {
  outline-offset: -2px;
}
.box-text {
  font-size: 0.9em;
  padding-bottom: 1.4em;
  padding-top: 0.7em;
  position: relative;
  width: 100%;
}
.has-shadow .box-text {
  padding-left: 1.2em;
  padding-right: 1.2em;
}
@media (max-width: 549px) {
  .box-text {
    font-size: 85%;
  }
}
.box-image {
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.box-image[style*="-radius"] {
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}
.box-image-inner {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.box-text.text-center {
  padding-left: 10px;
  padding-right: 10px;
}
.box-image img {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}
.box-label {
  overflow: hidden;
}
.box-label .box-text.text-center {
  left: 50%;
  margin: 10% 0;
  transform: translateX(-50%);
}
.box-label .box-text.text-right {
  left: auto;
  right: 0;
}
.box-label .box-text {
  background-color: #fff;
  bottom: 0;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 10%;
  opacity: 0.95;
  padding: 0.5em 1.5em 0.55em;
  position: absolute;
  width: auto;
}
.box-label:hover .box-text {
  opacity: 1;
}
.box-badge {
  overflow: hidden;
}
.box-badge .box-text {
  background-color: hsla(0, 0%, 100%, 0.95);
  bottom: 15%;
  padding: 0.8em 1em 1em;
  position: absolute;
  transition: background 0.3s;
  z-index: 1;
}
.dark .box-badge .box-text {
  background-color: rgba(0, 0, 0, 0.95);
}
.box-badge:hover .box-text {
  background-color: #446084;
}
.box-bounce .box-text {
  padding: 15px 15px 20px;
  z-index: 1;
}
.box-bounce:hover .box-text {
  background-color: #fff;
  transform: translateY(-15px);
}
.dark .box-bounce:hover .box-text {
  background-color: #222;
}
.box-bounce:hover .box-image {
  transform: translateY(-5px);
}
.box-push .box-text {
  background-color: hsla(0, 0%, 100%, 0.95);
  margin: -15% auto 0;
  max-width: 85%;
  padding: 15px 20px;
}
.box-overlay .box-text,
.box-shade .box-text {
  bottom: 0;
  max-height: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  position: absolute;
  width: 100%;
}
.box-overlay.dark .box-text,
.box-shade.dark .box-text {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.box-none .box-text {
  display: none !important;
}
.has-post-icon:hover .box-overlay .box-text,
.has-post-icon:hover .box-shade .box-text {
  opacity: 0 !important;
}
.box-text-middle.box-overlay .box-text,
.box-text-middle.box-shade .box-text {
  bottom: auto !important;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 16;
}
.box-text-top.box-overlay .box-text,
.box-text-top.box-shade .box-text {
  bottom: auto !important;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 16;
}
.box-vertical .box-text-middle .box-text {
  bottom: auto;
  position: relative;
  top: auto;
  transform: translateY(0);
}
.has-box-vertical .col {
  max-width: 100% !important;
  width: 100% !important;
}
@media screen and (max-width: 549px) {
  .box-vertical .box-image {
    width: 100% !important;
  }
}
@media screen and (min-width: 550px) {
  .box-list-view .col {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .box-list-view .box,
  .box-vertical {
    display: table;
    width: 100%;
  }
  .box-list-view .box .image-right,
  .box-vertical .image-right {
    direction: rtl;
  }
  .box-list-view .box .box-image,
  .box-list-view .box .box-text,
  .box-vertical .box-image,
  .box-vertical .box-text {
    direction: ltr;
    display: table-cell;
    vertical-align: middle;
  }
  .box-list-view .box .box-text,
  .box-vertical .box-text {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  .box-list-view .box .box-image,
  .box-vertical .box-image {
    width: 50%;
  }
  .box-list-view .box.align-top .box-text,
  .box-vertical.align-top .box-text {
    vertical-align: top;
  }
}
.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  text-rendering: optimizeLegibility;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  font-size: 0.97em;
  font-weight: bolder;
  letter-spacing: 0.03em;
  line-height: 2.4em;
  margin-right: 1em;
  margin-top: 0;
  max-width: 100%;
  min-height: 2.5em;
  padding: 0 1.2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s;
  vertical-align: middle;
}
.button span {
  display: inline-block;
  line-height: 1.6;
}
.button.is-outline {
  line-height: 2.19em;
}
.nav > li > a.button,
.nav > li > a.button:hover {
  padding-bottom: 0;
  padding-top: 0;
}
.nav > li > a.button:hover {
  color: #fff;
}
.button,
input[type="button"].button,
input[type="reset"].button,
input[type="submit"],
input[type="submit"].button {
  background-color: #446084;
  background-color: var(--primary-color);
  border-color: rgba(0, 0, 0, 0.05);
  color: #fff;
}
.button.is-outline,
input[type="button"].is-outline,
input[type="reset"].is-outline,
input[type="submit"].is-outline {
  background-color: transparent;
  border: 2px solid;
}
.button:hover,
.dark .button.is-form:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
  color: #fff;
  opacity: 1;
  outline: none;
}
.button.is-link,
.button.is-underline {
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0.15em;
  padding-right: 0.15em;
}
.button.is-underline:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  height: 2px;
  left: 20%;
  opacity: 0.3;
  position: absolute;
  transition: all 0.3s;
  width: 60%;
}
.button.is-underline:hover:before {
  left: 0;
  opacity: 1;
  width: 100%;
}
.is-link,
.is-underline {
  color: #666;
}
.dark .button.is-link:hover,
.dark .button.is-underline:hover {
  color: #fff;
  opacity: 0.8;
}
.button.is-link:hover,
.button.is-underline:hover {
  box-shadow: none;
  color: #333;
}
.is-form,
button.is-form,
input[type="button"].is-form,
input[type="reset"].is-form,
input[type="submit"].is-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  color: #666;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-shadow: 1px 1px 1px #fff;
  text-transform: none;
}
.button.is-form:hover {
  background-color: #f1f1f1;
  color: #000;
}
.dark .button,
.dark input[type="button"],
.dark input[type="reset"],
.dark input[type="submit"] {
  color: #fff;
}
.dark .button.is-form,
.dark input[type="button"].is-form,
.dark input[type="reset"].is-form,
.dark input[type="submit"].is-form {
  color: #000;
}
.is-round {
  border-radius: 99px;
}
.is-bevel,
.is-gloss,
.is-shade {
  overflow: hidden;
}
.is-bevel:after,
.is-gloss:after,
.is-shade:after {
  box-shadow: inset 0 1.2em 0 0 hsla(0, 0%, 100%, 0.1);
  content: "";
  height: 100%;
  left: -25%;
  position: absolute;
  top: 0;
  width: 150%;
}
.is-shade:after {
  box-shadow: inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.1),
    inset 0 2em 15px 0 hsla(0, 0%, 100%, 0.2);
}
.is-bevel:after {
  box-shadow: inset -0.01em -0.1em 0 0 rgba(0, 0, 0, 0.15);
  left: 0;
  width: 100%;
}
.is-outline {
  color: silver;
}
.is-outline:hover {
  box-shadow: none !important;
}
.primary,
input[type="submit"],
input[type="submit"].button {
  background-color: #446084;
}
.primary.is-link,
.primary.is-outline,
.primary.is-underline {
  color: #446084;
}
.is-outline:hover,
.primary.is-outline:hover {
  background-color: #446084;
  border-color: #446084;
  color: #fff;
}
.success {
  background-color: #7a9c59;
}
.success.is-link,
.success.is-outline,
.success.is-underline {
  color: #7a9c59;
}
.success.is-outline:hover {
  background-color: #7a9c59;
  border-color: #7a9c59;
  color: #fff;
}
.white {
  background-color: #fff !important;
  color: #666 !important;
}
.white.button.is-outline {
  background-color: transparent;
  color: currentColor !important;
}
.white.is-link,
.white.is-outline,
.white.is-underline {
  background-color: transparent !important;
  color: #fff !important;
}
.white.is-outline:hover {
  background-color: #fff !important;
  border-color: #fff;
  color: #666 !important;
}
.button.alt,
.button.checkout,
.checkout-button,
.secondary {
  background-color: #d26e4b;
}
.secondary.is-link,
.secondary.is-outline,
.secondary.is-underline {
  color: #d26e4b;
}
.secondary.is-outline:hover {
  background-color: #d26e4b;
  border-color: #d26e4b;
  color: #fff;
}
a.primary:not(.button),
a.secondary:not(.button) {
  background-color: transparent;
}
.alert {
  background-color: #b20000;
}
.alert.is-link,
.alert.is-outline,
.alert.is-underline {
  color: #b20000;
}
.alert.is-outline:hover {
  background-color: #b20000;
  border-color: #b20000;
  color: #fff;
}
.text-center .button:first-of-type {
  margin-left: 0 !important;
}
.text-center .button:last-of-type,
.text-right .button:last-of-type {
  margin-right: 0 !important;
}
.button.disabled,
.button[disabled],
button.disabled,
button[disabled] {
  opacity: 0.6;
}
.header-buttons .button {
  margin-bottom: 0;
  margin-right: 0.65em;
}
.header-button {
  display: inline-block;
}
.header-button .plain.is-outline:not(:hover) {
  color: #999;
}
.nav-dark .header-button .plain.is-outline:not(:hover) {
  color: #fff;
}
.header-button .button {
  margin: 0;
}
.scroll-for-more {
  margin-left: 0 !important;
}
.scroll-for-more:not(:hover) {
  opacity: 0.7;
}
.flex-col .button,
.flex-col button,
.flex-col input {
  margin-bottom: 0;
}
code {
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 0.9em;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
}
pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}
.dark code {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: #000;
}
.is-divider {
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  height: 3px;
  margin: 1em 0;
  max-width: 30px;
  width: 100%;
}
.widget .is-divider {
  margin-top: 0.66em;
}
.dark .is-divider {
  background-color: hsla(0, 0%, 100%, 0.3);
}
form {
  margin-bottom: 0;
}
.select-resize-ghost,
.select2-container .select2-choice,
.select2-container .select2-selection,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: #333;
  font-size: 0.97em;
  height: 2.507em;
  max-width: 100%;
  padding: 0 0.75em;
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  vertical-align: middle;
  width: 100%;
}
input:hover,
select:hover,
textarea:hover {
  box-shadow: inset 0 -1.8em 1em 0 transparent;
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea {
  min-height: 120px;
  padding-top: 0.7em;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
select:focus,
textarea:focus {
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  color: #333;
  opacity: 1 !important;
  outline: 0;
}
label,
legend {
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin-bottom: 0.4em;
}
legend {
  border-bottom: 1px solid #f1f1f1;
  font-size: 1em;
  font-weight: 700;
  margin: 30px 0 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}
fieldset {
  border-width: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline;
  font-size: 16px;
  margin-right: 10px;
}
label > .label-body {
  display: inline-block;
  font-weight: 400;
  margin-left: 0.5em;
}
abbr[title="required"] {
  color: #b20000;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline;
}
.select-resize-ghost,
.select2-container .select2-choice,
.select2-container .select2-selection,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 0.45em top 50%;
  background-repeat: no-repeat;
  background-size: auto 16px;
  border-radius: 0;
  box-shadow: inset 0 -1.4em 1em 0 rgba(0, 0, 0, 0.02);
  display: block;
  padding-right: 1.4em;
}
.select-resize-ghost {
  display: inline-block;
  opacity: 0;
  position: absolute;
  width: auto;
}
select.resize-select {
  width: 55px;
}
.select2-selection__arrow b {
  border-color: transparent !important;
}
.select2-container .selection .select2-selection--multiple {
  background-image: none;
  height: auto;
  line-height: 1.7em;
  padding: 10px;
}
.select2-container
  .selection
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 5px;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
  select:focus::-ms-value {
    background: transparent;
    color: currentColor;
  }
}
.form-flat input:not([type="submit"]),
.form-flat select,
.form-flat textarea {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(0, 0, 0, 0.09);
  border-radius: 99px;
  box-shadow: none;
  color: currentColor !important;
}
.form-flat button,
.form-flat input {
  border-radius: 99px;
}
.form-flat textarea {
  border-radius: 15px;
}
.flex-row.form-flat .flex-col,
.form-flat .flex-row .flex-col {
  padding-right: 4px;
}
.dark .form-flat input:not([type="submit"]),
.dark .form-flat select,
.dark .form-flat textarea,
.nav-dark .form-flat input:not([type="submit"]),
.nav-dark .form-flat select,
.nav-dark .form-flat textarea {
  background-color: hsla(0, 0%, 100%, 0.2) !important;
  border-color: hsla(0, 0%, 100%, 0.09);
  color: #fff;
}
.dark .form-flat select option {
  color: #333;
}
.dark .form-flat select,
.nav-dark .form-flat select {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.dark .form-flat input::-webkit-input-placeholder,
.nav-dark .form-flat input::-webkit-input-placeholder {
  color: #fff;
}
.form-flat .button.icon {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none;
  color: currentColor !important;
}
.form-minimal input:not([type="submit"]),
.form-minimal select,
.form-minimal textarea {
  background-color: transparent;
  box-shadow: none;
}
.form-minimal.quantity .qty {
  border-left: 0;
  border-right: 0;
  max-width: 2em;
}
.woocommerce-invalid input,
input.wpcf7-not-valid {
  border-color: #b20000;
  color: #b20000;
}
.woocommerce-invalid select {
  border-color: #b20000;
}
.woocommerce-invalid input.input-checkbox {
  outline: 1px solid #b20000;
  outline-offset: 1px;
}
.select2-container {
  text-align: left;
}
.form-row .select2-container {
  margin-bottom: 1em;
}
.select2-search__field {
  margin-bottom: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  width: 30px;
}
.select2-container .select2-choice > .select2-chosen,
.select2-container .select2-selection--single .select2-selection__rendered,
.select2-selection {
  line-height: 2.507em;
  padding: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 2.507em;
}
.fl-wrap {
  position: relative;
}
.loading-site .fl-labels label[for]:first-child {
  display: none;
}
.fl-labels .fl-wrap label[for]:first-child {
  font-size: 12px;
  font-weight: 400;
  left: 1.1em;
  line-height: 1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0.5em;
  transition: all 0.3s;
  white-space: nowrap;
}
.dark .fl-wrap label {
  color: #000;
}
.fl-wrap.fl-is-active > label[for]:first-child {
  opacity: 0.8;
  z-index: 1;
}
.fl-labels .form-row input:not([type="checkbox"]),
.fl-labels .form-row select,
.fl-labels .form-row textarea {
  height: 3.0084em;
  transition: padding 0.3s;
}
.form-row .fl-is-active input,
.form-row .fl-is-active textarea {
  padding-bottom: 0.5em;
  padding-top: 1.1em;
}
.form-row .fl-is-active select {
  padding-bottom: 0.4em;
  padding-top: 1.1em;
}
.icon-box .icon-box-img {
  margin-bottom: 1em;
  max-width: 100%;
  position: relative;
}
.flickity-slider > .icon-box,
.slider > .icon-box {
  padding-left: 3% !important;
  padding-right: 3% !important;
}
.icon-box-img img,
.icon-box-img svg {
  padding-top: 0.2em;
  width: 100%;
}
.icon-box-img svg {
  fill: currentColor;
  height: 100%;
  transition: all 0.3s;
  width: 100%;
}
.icon-box-img svg path {
  fill: currentColor;
}
.icon-box:hover .has-icon-bg i,
.icon-box:hover .has-icon-bg svg,
.icon-box:hover .has-icon-bg svg path {
  fill: #fff;
}
.has-icon-bg .icon {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}
.has-icon-bg .icon .icon-inner {
  border: 2px solid;
  border-radius: 100%;
  color: #446084;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 100%;
}
.icon-box:hover .has-icon-bg .icon .icon-inner {
  background-color: currentColor;
}
.has-icon-bg .icon i,
.has-icon-bg .icon img,
.has-icon-bg .icon svg {
  left: 0;
  margin: 0 20%;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
}
.icon-box-left .has-icon-bg .icon {
  position: absolute;
  width: 100%;
}
.icon-box-left,
.icon-box-right {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.icon-box-left .icon-box-img,
.icon-box-right .icon-box-img {
  flex: 0 0 auto;
  margin-bottom: 0;
  max-width: 200px;
}
.icon-box-left .icon-box-text,
.icon-box-right .icon-box-text {
  flex: 1 1 0px;
}
.icon-box-right .icon-box-text {
  order: -1;
}
.icon-box-left .icon-box-img + .icon-box-text {
  padding-left: 1em;
}
.icon-box-right .icon-box-img + .icon-box-text {
  padding-right: 1em;
}
.icon-box-center .icon-box-img {
  margin: 0 auto 1em;
}
.links > li > a:before,
i[class*=" icon-"],
i[class^="icon-"] {
  speak: none !important;
  display: inline-block;
  font-display: block;
  font-family: fl-icons !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: 400 !important;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: none !important;
}
.widget-title i {
  margin-right: 4px;
  opacity: 0.35;
}
.button i,
button i {
  top: -1.5px;
  vertical-align: middle;
}
.button.open-video i {
  top: -0.027em;
}
a.icon-remove,
a.remove {
  border: 2px solid;
  border-radius: 100%;
  color: #ccc;
  display: block;
  font-size: 15px !important;
  font-weight: 700;
  height: 24px;
  line-height: 19px !important;
  text-align: center;
  width: 24px;
}
a.icon:not(.button),
button.icon:not(.button) {
  font-family: sans-serif;
  font-size: 1.2em;
  margin-left: 0.25em;
  margin-right: 0.25em;
}
.header a.icon:not(.button) {
  margin-left: 0.3em;
  margin-right: 0.3em;
}
.header .nav-small a.icon:not(.button) {
  font-size: 1em;
}
.button.icon {
  display: inline-block;
  margin-left: 0.12em;
  margin-right: 0.12em;
  min-width: 2.5em;
  padding-left: 0.6em;
  padding-right: 0.6em;
}
.button.icon i {
  font-size: 1.2em;
}
.button.icon i + span {
  padding-right: 0.5em;
}
.button.icon.is-small {
  border-width: 1px;
}
.button.icon.is-small i {
  top: -1px;
}
.button.icon.circle {
  padding-left: 0;
  padding-right: 0;
}
.button.icon.circle > span {
  margin: 0 8px;
}
.button.icon.circle > span + i {
  margin-left: -4px;
}
.button.icon.circle > i {
  margin: 0 8px;
}
.button.icon.circle > i + span {
  margin-left: -4px;
}
.button.icon.circle > i:only-child {
  margin: 0;
}
.nav > li > a > i {
  font-size: 20px;
  transition: color 0.3s;
  vertical-align: middle;
}
.nav > li > a > i + span {
  margin-left: 5px;
}
.nav li.has-icon-left > a > i,
.nav li.has-icon-left > a > img,
.nav li.has-icon-left > a > svg {
  margin-right: 5px;
}
.nav > li > a > span + i {
  margin-left: 5px;
}
.nav-small > li > a > i {
  font-size: 1em;
}
.nav > li > a > i.icon-search {
  font-size: 1.2em;
}
.nav > li > a > i.icon-menu {
  font-size: 1.9em;
}
.nav > li.has-icon > a > i {
  min-width: 1em;
}
.nav-vertical > li > a > i {
  font-size: 16px;
  opacity: 0.6;
}
.header-button a.icon:not(.button) {
  display: inline-block;
  padding: 6px 0;
}
.header-button a.icon:not(.button) i {
  font-size: 24px;
}
.header-button a.icon:not(.button) i.icon-search {
  font-size: 16px;
}
.nav-small .header-button a.icon:not(.button) {
  padding: 3px 0;
}
.button:not(.icon) > i {
  margin-left: -0.15em;
  margin-right: 0.4em;
}
.button:not(.icon) > span + i {
  margin-left: 0.4em;
  margin-right: -0.15em;
}
.has-dropdown .icon-angle-down {
  font-size: 16px;
  margin-left: 0.2em;
  opacity: 0.6;
}
.overlay-icon {
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  border-radius: 100%;
  color: #fff;
  height: 3em;
  line-height: 2.8em;
  opacity: 0.8;
  text-align: center;
  transition: transform 0.2s, opacity 0.2s;
  width: 3em;
}
.overlay-icon i {
  font-size: 1.3em;
  top: 3px;
  transition: color 0.3s;
}
.has-hover:hover .overlay-icon {
  opacity: 1;
  transform: scale(1.15);
}
.box-overlay .overlay-icon,
.box-shade .overlay-icon {
  opacity: 0;
}
.image-icon {
  display: inline-block;
  height: auto;
  position: relative;
  vertical-align: middle;
}
span + .image-icon {
  margin-left: 10px;
}
.image-icon img {
  max-width: 32px;
  min-width: 100%;
}
.nav-small .image-icon {
  height: 16px;
  margin: 0 5px;
  width: 16px;
}
.nav-small .image-icon img {
  vertical-align: top;
}
[data-icon-label] {
  position: relative;
}
[data-icon-label="0"]:after {
  display: none;
}
[data-icon-label]:after {
  background-color: #d26e4b;
  border-radius: 99px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  content: attr(data-icon-label);
  font-family: Arial, Sans-serif !important;
  font-size: 11px;
  font-style: normal;
  font-weight: bolder;
  height: 17px;
  letter-spacing: -0.5px;
  line-height: 17px;
  min-width: 17px;
  opacity: 0.9;
  padding-left: 2px;
  padding-right: 2px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  transition: transform 0.2s;
  z-index: 1;
}
.nav-small [data-icon-label]:after {
  font-size: 10px;
  height: 13px;
  line-height: 13px;
  min-width: 13px;
  right: -10px;
  top: -4px;
}
.button [data-icon-label]:after {
  right: -12px;
  top: -12px;
}
.reveal-icon i {
  width: 1em;
}
.reveal-icon i,
.reveal-icon span {
  display: inline-block;
  transition: opacity 0.3s, transform 0.3s;
}
.reveal-icon span {
  transform: translateX(0.75em);
}
.reveal-icon i + span {
  transform: translateX(-0.75em);
}
.reveal-icon:hover > span {
  transform: translateX(0);
}
.reveal-icon:not(:hover) i {
  opacity: 0;
}
img {
  opacity: 1;
  transition: opacity 1s;
}
.col-inner > .img:not(:last-child),
.col > .img:not(:last-child) {
  margin-bottom: 1em;
}
.img,
.img .img-inner {
  position: relative;
}
.img .img-inner {
  overflow: hidden;
}
.img,
.img img {
  width: 100%;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.15);
  height: 100%;
  left: -1px;
  pointer-events: none;
  right: -1px;
  top: 0;
  transition: transform 0.3s, opacity 0.6s;
}
.img .caption,
.overlay {
  bottom: 0;
  position: absolute;
}
.img .caption {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 14px;
  max-height: 100% !important;
  overflow-y: auto;
  padding: 4px 10px;
  transform: translateY(100%);
  transition: transform 0.5s;
  width: 100%;
}
.img .caption-show,
.img:hover .caption {
  transform: translateY(0);
}
.lazy-load {
  background-color: rgba(0, 0, 0, 0.03);
}
.has-format,
.image-cover,
.video-fit {
  background-position: 50% 50%;
  background-size: cover;
  height: auto;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}
.video-fit {
  padding-top: 56.5%;
}
.has-equal-box-heights .box-image img,
.has-format img,
.image-cover img,
.video-fit iframe,
.video-fit video,
img.back-image {
  bottom: 0;
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.has-equal-box-heights .box-image {
  padding-top: 100%;
}
.video-fit iframe {
  -o-object-fit: fill;
  object-fit: fill;
}
.image-tools {
  padding: 10px;
  position: absolute;
}
.image-tools a:last-child {
  margin-bottom: 0;
}
.image-tools a.button {
  display: block;
}
.image-tools.bottom.left {
  padding-right: 0;
  padding-top: 0;
}
.image-tools.top.right {
  padding-bottom: 0;
  padding-left: 0;
}
.image-tools .cart-icon {
  bottom: 100%;
  left: 10px;
  margin: 5px;
  position: absolute;
}
.image-tools .wishlist-button:hover {
  background-color: #b20000;
  border-color: #b20000;
}
@media only screen and (max-device-width: 1024px) {
  .image-tools {
    opacity: 1 !important;
    padding: 5px;
  }
}
.overlay-tools {
  margin-top: 10px;
}
.overlay-tools a {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 99px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 5px;
  padding: 2px 10px 3px;
  text-shadow: none;
  transition: all 0.3s;
}
.overlay-tools a:hover {
  background-color: #fff;
  color: #000 !important;
}
.tag-label {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 0.65em;
  font-weight: 700;
  letter-spacing: 1.1px;
  line-height: 1;
  padding: 2px 5px;
  text-transform: uppercase;
  transition: background-color 0.3s, opacity 0.3s;
}
.has-hover:hover .tag-label {
  background-color: #d26e4b;
  opacity: 1;
}
.lightbox-content {
  background-color: #fff;
  box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  max-width: 875px;
  position: relative;
}
.lightbox-content .lightbox-inner {
  padding: 30px 20px;
}
.pswp__bg {
  background-color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 549px) {
  .pswp__top-bar {
    background-color: transparent !important;
  }
}
.pswp__item {
  opacity: 0;
  transition: opacity 0.5s;
}
.pswp--visible .pswp__item {
  opacity: 1;
}
.pswp__caption__center {
  text-align: center;
}
.mfp-bg {
  background: #0b0b0b;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.25s;
  width: 100%;
  z-index: 1042;
}
.mfp-bg.mfp-ready {
  opacity: 0.6;
}
.mfp-bg.mfp-removing {
  opacity: 0 !important;
}
.mfp-wrap {
  -webkit-backface-visibility: hidden;
  outline: none !important;
  position: fixed;
  z-index: 1043;
}
.mfp-container,
.mfp-wrap {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.mfp-container {
  box-sizing: border-box;
  padding: 30px;
  position: absolute;
  text-align: center;
}
.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-image-holder {
  padding: 40px 6.66%;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0;
  position: relative;
  text-align: left;
  transform: translateY(-30px);
  transition: transform 0.5s, opacity 0.3s;
  vertical-align: middle;
  z-index: 1045;
}
.mfp-content-inner {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.mfp-ready .mfp-content {
  opacity: 1;
  transform: translateY(0);
}
.mfp-ready.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  cursor: auto;
  width: 100%;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-preloader {
  color: #ccc;
  left: 8px;
  margin-top: -0.8em;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 50%;
  width: auto;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none;
}
.mfp-close {
  -webkit-backface-visibility: hidden;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-family: Arial, monospace;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  margin: 0 !important;
  min-height: 0;
  mix-blend-mode: difference;
  opacity: 0;
  outline: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  transform: translateY(50%) translateZ(1px);
  transition: opacity 0.6s, transform 0.6s;
  width: 40px;
  z-index: 1046;
}
.mfp-close svg {
  pointer-events: none;
}
.mfp-content .mfp-close {
  position: absolute;
}
.mfp-ready .mfp-close {
  opacity: 0.6;
  transform: translateY(0) translateZ(1px);
}
.mfp-removing .mfp-close {
  display: none !important;
}
.mfp-close:hover {
  opacity: 1;
}
.mfp-close-btn-in .mfp-close {
  color: currentColor;
  top: 0 !important;
}
.mfp-counter {
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: 0;
  top: 0;
}
.mfp-arrow {
  color: #fff;
  height: 50px;
  margin: 0;
  mix-blend-mode: difference;
  opacity: 0.6;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 1046;
}
.mfp-arrow i {
  font-size: 42px !important;
  pointer-events: none;
}
.mfp-arrow:hover {
  opacity: 1;
}
.mfp-arrow:active {
  top: 50.1%;
}
.mfp-arrow-left {
  left: 0;
  transform: translateX(100%);
}
.mfp-arrow-right {
  right: 0;
  transform: translateX(-100%);
}
.mfp-ready .mfp-arrow-left,
.mfp-ready .mfp-arrow-right {
  transform: translateX(0);
}
.mfp-iframe-holder {
  padding-bottom: 40px;
  padding-top: 40px;
}
.mfp-iframe-holder .mfp-content,
.mfp-inline-holder .ux-mfp-inline-content--video {
  line-height: 0;
  max-width: 900px;
  width: 100%;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}
.mfp-iframe-scaler {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%;
}
.mfp-iframe-scaler iframe {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.mfp-iframe-scaler iframe,
.mfp-inline-holder .ux-mfp-inline-content--video {
  background: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.mfp-inline-holder .ux-mfp-inline-content,
img.mfp-img {
  margin: 0 auto;
}
img.mfp-img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: block;
  height: auto;
  max-height: calc(100vh - 80px);
  max-width: 100%;
  padding: 0 0 40px;
  width: auto;
}
.mfp-figure,
img.mfp-img {
  line-height: 0;
}
.mfp-figure:after {
  background: #444;
  bottom: 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  content: "";
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: -1;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-bottom-bar {
  cursor: auto;
  left: 0;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  width: 100%;
}
.mfp-title {
  word-wrap: break-word;
  color: #f3f3f3;
  font-size: 11px;
  line-height: 18px;
  padding-right: 36px;
  text-align: left;
}
.mfp-title a {
  color: #f3f3f3;
  text-decoration: underline;
}
.mfp-title a:hover {
  color: #f3f3f3;
  opacity: 0.8;
  text-decoration: none;
}
.mfp-image-holder .mfp-content {
  max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-height: 300px),
  screen and (max-width: 800px) and (orientation: landscape) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    bottom: 0;
    top: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
}
@media (max-width: 849px) {
  body.body-scroll-lock--active .mfp-wrap {
    overflow: auto;
    position: fixed !important;
  }
}
@media (prefers-reduced-motion) {
  .mfp-bg,
  .mfp-close,
  .mfp-content {
    transition: opacity 0.3s;
  }
}
.loading-spin,
.processing {
  position: relative;
}
.loading-spin,
.processing:before {
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1) !important;
  border-left: 3px solid #446084;
  border-radius: 50%;
  border-right: 3px solid rgba(0, 0, 0, 0.1) !important;
  border-top: 3px solid rgba(0, 0, 0, 0.1) !important;
  content: "";
  font-size: 10px;
  height: 30px;
  margin: 0 auto;
  opacity: 0.8;
  pointer-events: none;
  text-indent: -9999em;
  width: 30px;
}
.box-image.processing:before,
.dark .loading-spin,
.dark .processing:before,
.dark.loading-spin,
.dark.processing:before {
  border: 3px solid rgba(0, 0, 0, 0.1) !important;
  border-left-color: #fff !important;
}
.box-image.processing:after {
  background-color: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.1;
  position: absolute;
  right: 0;
  top: 0;
}
.box-image.processing .image-tools {
  opacity: 0 !important;
}
.woocommerce-checkout.processing:before {
  position: fixed;
}
.loading-spin.centered,
.processing:before {
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  z-index: 99;
}
.loading-spin {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.button.loading {
  color: hsla(0, 0%, 100%, 0.05);
  opacity: 1 !important;
  pointer-events: none !important;
  position: relative;
}
.button.loading:after {
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  border: 2px solid #fff;
  border-radius: 32px;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  content: "";
  display: block;
  height: 16px;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.is-outline .button.loading:after {
  border-color: #446084;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.ux-menu-link {
  align-items: center;
}
.ux-menu-link--active .ux-menu-link__link,
.ux-menu-link:hover .ux-menu-link__link {
  color: hsla(0, 0%, 7%, 0.85);
}
.dark .ux-menu-link__link {
  border-bottom-color: hsla(0, 0%, 100%, 0.2);
}
.dark .ux-menu-link--active .ux-menu-link__link,
.dark .ux-menu-link:hover .ux-menu-link__link {
  color: hsla(0, 0%, 100%, 0.85);
}
.ux-menu.ux-menu--divider-solid
  .ux-menu-link:not(:last-of-type)
  .ux-menu-link__link {
  border-bottom-style: solid;
}
.ux-menu-link__link {
  align-items: baseline;
  border-bottom-color: #ececec;
  border-bottom-width: 1px;
  color: currentColor;
  flex: 1;
  min-height: 2.5em;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  transition: all 0.2s;
}
.ux-menu-link__icon {
  flex-shrink: 0;
  vertical-align: middle;
  width: 16px;
}
.ux-menu-link__icon + .ux-menu-link__text {
  padding-left: 0.5em;
}
.ux-menu-title {
  align-items: center;
  color: #000;
  font-size: 0.8em;
  font-weight: bolder;
  margin-top: 5px;
  min-height: 3.125em;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  text-transform: uppercase;
}
.ux-menu-link + .ux-menu-title {
  margin-top: 5px !important;
}
.dark .ux-menu-title {
  color: currentColor;
}
.off-canvas .mfp-content {
  -ms-scrollbar-base-color: #000;
  -ms-scrollbar-arrow-color: #000;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.off-canvas .nav-vertical > li > a {
  padding-bottom: 15px;
  padding-top: 15px;
}
.off-canvas .nav-vertical li li > a {
  padding-bottom: 5px;
  padding-top: 5px;
}
.off-canvas .hide-for-off-canvas {
  display: none !important;
}
.off-canvas .hide-for-medium,
.off-canvas .hide-for-small {
  display: inline-block !important;
}
.off-canvas-left .mfp-content,
.off-canvas-right .mfp-content {
  -webkit-overflow-scrolling: touch;
  background-color: hsla(0, 0%, 100%, 0.95);
  bottom: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transform: translateX(-270px);
  transition: transform 0.2s;
  width: 260px;
}
.off-canvas-left.dark .mfp-content,
.off-canvas-right.dark .mfp-content {
  background-color: rgba(0, 0, 0, 0.95);
}
.off-canvas-right .mfp-content {
  left: auto;
  right: 0;
  transform: translateX(270px);
}
.off-canvas-center .nav-vertical > li > a,
.off-canvas-center .nav-vertical > li > ul > li a {
  justify-content: center;
}
.off-canvas-center .nav-vertical > li > a {
  font-size: 1.5em;
}
.off-canvas-center .mfp-container {
  padding: 0;
}
.off-canvas-center .mfp-content {
  -webkit-overflow-scrolling: touch;
  max-width: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 50px;
}
.off-canvas-center .mfp-content .searchform {
  margin: 0 auto;
  max-width: 260px;
}
.off-canvas-center.mfp-bg.mfp-ready {
  opacity: 0.85;
}
.mfp-bg.off-canvas-center:not(.dark) {
  background-color: #fff;
  opacity: 0.95;
}
.off-canvas-center .nav-sidebar.nav-vertical > li {
  border: 0;
}
.off-canvas-center .nav-sidebar.nav-vertical > li > a {
  padding-left: 30px;
  padding-right: 30px;
}
.off-canvas-center .nav-sidebar.nav-vertical > li .toggle {
  position: absolute;
  right: 0;
  top: 15px;
}
.off-canvas-center:not(.dark) .mfp-close {
  color: currentColor;
}
.off-canvas.mfp-removing .mfp-content {
  opacity: 0 !important;
}
.off-canvas-left.mfp-ready .mfp-content,
.off-canvas-right.mfp-ready .mfp-content {
  opacity: 1;
  transform: translate(0);
}
.has-off-canvas .off-canvas-blur {
  filter: blur(5px);
}
.has-off-canvas .off-canvas-zoom {
  transform: scale(0.98);
}
.has-off-canvas .current-lightbox-clicked {
  opacity: 0 !important;
  transform: translateY(-7px);
}
.has-off-canvas.has-off-canvas-left .current-lightbox-clicked,
.has-off-canvas.has-off-canvas-right .current-lightbox-clicked {
  transform: translateX(-7px);
}
.off-canvas .sidebar-menu {
  padding: 30px 0;
}
.off-canvas .sidebar-inner {
  padding: 30px 20px;
}
.off-canvas:not(.off-canvas-center) .nav-vertical li > a {
  padding-left: 20px;
}
.off-canvas:not(.off-canvas-center) li.html {
  padding: 20px;
}
@media (prefers-reduced-motion) {
  .off-canvas-left .mfp-content,
  .off-canvas-right .mfp-content {
    transition: opacity 0.2s;
  }
}
.slider-wrapper {
  position: relative;
}
.flickity-slider > .img,
.slider-wrapper:last-child,
.slider > .img {
  margin-bottom: 0;
}
.row-slider,
.slider {
  position: relative;
  scrollbar-width: none;
}
.slider-full .flickity-slider > .col {
  padding: 0 !important;
}
.slider-load-first {
  max-height: 999px;
  transition: max-height 2s;
}
.slider-load-first:not(.flickity-enabled) {
  max-height: 500px;
}
.slider-load-first:not(.flickity-enabled) > div {
  opacity: 0;
}
.slider:not(.flickity-enabled) {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
}
.row-slider::-webkit-scrollbar,
.slider::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}
.row.row-slider:not(.flickity-enabled) {
  display: block;
}
.slider:not(.flickity-enabled) > * {
  display: inline-block !important;
  vertical-align: top;
  white-space: normal !important;
}
.slider:not(.flickity-enabled) > a,
.slider > a,
.slider > a > img,
.slider > div:not(.col),
.slider > img,
.slider > p {
  width: 100%;
}
.flickity-slider > a,
.flickity-slider > a > img,
.flickity-slider > div:not(.col),
.flickity-slider > figure,
.flickity-slider > img,
.flickity-slider > p {
  margin: 0;
  padding: 0;
  transition: transform 0.6s, opacity 0.6s, box-shadow 0.6s;
  width: 100% !important;
}
.flickity-slider > .row:not(.is-selected) {
  opacity: 0;
}
.flickity-enabled {
  display: block;
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-viewport {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.flickity-slider {
  height: 100%;
  position: absolute;
  width: 100%;
}
.slider-has-parallax .bg {
  transition: opacity 0.3s !important;
}
.is-dragging .flickity-viewport .flickity-slider {
  pointer-events: none;
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-prev-next-button {
  background-color: transparent;
  border: none;
  bottom: 40%;
  color: #111;
  cursor: pointer;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 40%;
  transition: transform 0.3s, opacity 0.3s, background 0.3s, box-shadow 0.3s;
  width: 36px;
}
.flickity-prev-next-button.next {
  right: 2%;
  transform: translateX(-20%);
}
.flickity-prev-next-button.previous {
  left: 2%;
  transform: translateX(20%);
}
.slider-show-nav .flickity-prev-next-button,
.slider:hover .flickity-prev-next-button {
  opacity: 0.7;
  transform: translateX(0) !important;
}
.slider .flickity-prev-next-button:hover {
  box-shadow: none;
  opacity: 1;
}
.slider .flickity-prev-next-button:hover .arrow,
.slider .flickity-prev-next-button:hover svg {
  fill: #446084;
}
@media (min-width: 850px) {
  .slider-nav-outside .flickity-prev-next-button.next {
    left: 100%;
    right: auto;
  }
  .slider-nav-outside .flickity-prev-next-button.previous {
    left: auto;
    right: 100%;
  }
}
.flickity-prev-next-button:disabled,
button.flickity-prev-next-button[disabled] {
  background: none !important;
  box-shadow: none !important;
  cursor: auto;
  opacity: 0 !important;
  pointer-events: none;
}
.flickity-prev-next-button svg {
  height: auto;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  padding: 20%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  width: 100%;
}
.slider-nav-push:not(.slider-nav-reveal) .flickity-prev-next-button {
  margin-top: -10%;
}
.slider-nav-push.slider-nav-reveal .flickity-prev-next-button svg {
  margin-top: -40px;
}
.flickity-prev-next-button .arrow,
.flickity-prev-next-button svg {
  fill: currentColor;
  border-color: currentColor;
  transition: all 0.3s;
}
.animate-height,
.slider-auto-height {
  transition: height 0.2s;
}
.flickity-prev-next-button.no-svg {
  color: #111;
  font-size: 26px;
}
.slider-no-arrows .flickity-prev-next-button {
  display: none !important;
}
.slider-type-fade .flickity-slider {
  transform: none !important;
}
.slider-type-fade .flickity-slider > * {
  left: 0 !important;
  opacity: 0;
  z-index: 1;
}
.slider-type-fade .flickity-slider > .is-selected {
  opacity: 1;
  z-index: 2;
}
.slider-type-fade .flickity-page-dots,
.slider-type-fade .flickity-prev-next-button {
  z-index: 3;
}
@media (prefers-reduced-motion) {
  .flickity-prev-next-button.next,
  .flickity-prev-next-button.previous {
    transform: none !important;
  }
}
.flickity-page-dots {
  bottom: 15px;
  left: 20%;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20%;
  text-align: center;
}
.row-slider .flickity-page-dots,
.slider-nav-outside .flickity-page-dots {
  bottom: 0;
}
.flickity-rtl .flickity-page-dots {
  direction: rtl;
}
.flickity-page-dots .dot {
  border: 3px solid #111;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  margin: 0 5px;
  opacity: 0.4;
  transition: opacity 0.3s;
  width: 12px;
}
.nav-dots-small .flickity-page-dots .dot,
.row-slider .flickity-page-dots .dot {
  background-color: #111;
  border: 0;
  height: 10px;
  opacity: 0.2;
  width: 10px;
}
.flickity-page-dots .dot:hover {
  opacity: 0.7;
}
.flickity-page-dots .dot:first-child:last-child {
  display: none;
}
.flickity-page-dots .dot.is-selected {
  background-color: #111;
  opacity: 1 !important;
}
.slider-nav-dots-dashes .flickity-page-dots .dot {
  border-radius: 0;
  height: 4px;
  margin: 0;
  width: 40px;
}
.slider-nav-dots-dashes-spaced .flickity-page-dots .dot {
  border-radius: 0;
  height: 4px;
  width: 40px;
}
.slider-nav-dots-simple .flickity-page-dots .dot {
  background-color: #111;
}
.slider-nav-dots-square .flickity-page-dots .dot {
  border-radius: 0;
}
.slider-nav-circle .flickity-prev-next-button .arrow,
.slider-nav-circle .flickity-prev-next-button svg {
  border: 2px solid;
  border-radius: 100%;
}
.slider-nav-circle .flickity-prev-next-button:hover .arrow,
.slider-nav-circle .flickity-prev-next-button:hover svg {
  fill: #fff !important;
  background-color: #446084;
  border-color: #446084;
}
.slider-nav-outside.slider-nav-circle .next {
  transform: translateX(-50%) !important;
}
.slider-nav-outside.slider-nav-circle .previous {
  transform: translateX(50%) !important;
}
.slider-nav-reveal {
  overflow: hidden;
  padding-top: 0;
}
.slider-nav-reveal .flickity-prev-next-button,
.slider-nav-reveal .flickity-prev-next-button:hover {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -150px 15px 0 rgba(0, 0, 0, 0.3);
  height: 100%;
  margin: 0 !important;
  top: -2px;
  width: 30px;
}
.dark .slider-nav-reveal .flickity-prev-next-button {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 99px;
  color: #fff;
}
.slider-nav-reveal .flickity-prev-next-button.next {
  left: auto !important;
  right: -1px !important;
  transform: translateX(10%);
}
.slider-nav-reveal .flickity-prev-next-button.previous {
  left: -1px !important;
  right: auto !important;
  transform: translateX(-10%);
}
.slider-nav-light .flickity-prev-next-button {
  color: #fff;
}
.slider-nav-light .flickity-prev-next-button .arrow,
.slider-nav-light .flickity-prev-next-button svg {
  fill: #fff;
}
.slider-nav-light .flickity-page-dots .dot {
  border-color: #fff;
}
.slider-nav-light .flickity-page-dots .dot.is-selected,
.slider-nav-light.slider-nav-dots-simple .flickity-page-dots .dot {
  background-color: #fff;
}
.slider-style-container .flickity-slider > *,
.slider-style-focus .flickity-slider > *,
.slider-style-shadow .flickity-slider > * {
  margin: 0 auto;
  max-width: 1080px;
}
.slider-style-container:not(.flickity-enabled) .ux_banner,
.slider-style-focus:not(.flickity-enabled) .ux_banner,
.slider-style-shadow:not(.flickity-enabled) .ux_banner {
  opacity: 0 !important;
}
.slider-style-container .ux_banner,
.slider-style-focus .ux_banner,
.slider-style-shadow .ux_banner {
  opacity: 1;
}
.slider-style-container .flickity-slider > :not(.is-selected),
.slider-style-focus .flickity-slider > :not(.is-selected),
.slider-style-shadow .flickity-slider > :not(.is-selected) {
  opacity: 0.5;
}
.slider-style-shadow {
  padding: 15px 0 25px;
}
.slider-style-shadow .flickity-slider > :before {
  /* background-image: url(../img/shadow@2x.png); */
  background-size: 100% 100%;
  bottom: -6px;
  content: "";
  height: 100px;
  left: -10%;
  position: absolute;
  right: -10%;
  z-index: -2;
}
.slider-style-shadow .flickity-slider > :not(.is-selected) {
  opacity: 1;
  transform: scale(0.9);
  z-index: -1;
}
.slider-style-shadow .flickity-slider > :not(.is-selected):before {
  height: 200px;
  opacity: 0.5;
}
.slider-style-shadow .flickity-slider > .is-selected {
  transform: scale(0.95);
}
.slider-style-focus {
  background-color: transparent;
  padding: 30px 0;
}
.slider-style-focus .flickity-page-dots {
  bottom: 6px;
}
.slider-style-focus .flickity-slider > * {
  max-width: 1050px;
}
.slider-style-focus .flickity-slider > :not(.is-selected) {
  transform: scale(0.93);
}
@media screen and (max-width: 549px) {
  .slider-wrapper .flickity-prev-next-button {
    display: none;
  }
  .row-slider .flickity-prev-next-button {
    background-color: transparent !important;
    box-shadow: none !important;
    opacity: 0.8;
    transform: translateX(0) !important;
    width: 20px !important;
  }
  .row-slider .flickity-prev-next-button svg {
    border: 0 !important;
    padding: 3px !important;
  }
  .flickity-page-dots {
    pointer-events: none;
  }
  .flickity-slider .banner-layers {
    overflow: hidden;
  }
}
.stack {
  --stack-gap: 0;
  display: flex;
}
.stack > .text > :first-child {
  margin-top: 0;
}
.stack > .text > :last-child {
  margin-bottom: 0;
}
.stack-row {
  flex-direction: row;
}
.stack-row > * {
  margin: 0 !important;
}
.stack-row > * ~ * {
  margin-left: var(--stack-gap) !important;
}
.stack-col {
  flex-direction: column;
}
.stack-col > * {
  margin: 0 !important;
}
.stack-col > * ~ * {
  margin-top: var(--stack-gap) !important;
}
.items-stretch {
  align-items: stretch;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
@media (max-width: 849px) {
  .md\:stack-row {
    flex-direction: row;
  }
  .md\:stack-row > * {
    margin: 0 !important;
  }
  .md\:stack-row > * ~ * {
    margin-left: var(--stack-gap) !important;
  }
  .md\:stack-col {
    flex-direction: column;
  }
  .md\:stack-col > * {
    margin: 0 !important;
  }
  .md\:stack-col > * ~ * {
    margin-top: var(--stack-gap) !important;
  }
  .md\:items-stretch {
    align-items: stretch;
  }
  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:items-baseline {
    align-items: baseline;
  }
  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:justify-around {
    justify-content: space-around;
  }
}
@media (max-width: 549px) {
  .sm\:stack-row {
    flex-direction: row;
  }
  .sm\:stack-row > * {
    margin: 0 !important;
  }
  .sm\:stack-row > * ~ * {
    margin-left: var(--stack-gap) !important;
  }
  .sm\:stack-col {
    flex-direction: column;
  }
  .sm\:stack-col > * {
    margin: 0 !important;
  }
  .sm\:stack-col > * ~ * {
    margin-top: var(--stack-gap) !important;
  }
  .sm\:items-stretch {
    align-items: stretch;
  }
  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-baseline {
    align-items: baseline;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:justify-around {
    justify-content: space-around;
  }
}
table {
  border-color: #ececec;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;
}
td,
th {
  border-bottom: 1px solid #ececec;
  font-size: 0.9em;
  line-height: 1.3;
  padding: 0.5em;
  text-align: left;
}
td {
  color: #666;
}
td:first-child,
th:first-child {
  padding-left: 0;
}
td:last-child,
th:last-child {
  padding-right: 0;
}
.dark td,
.dark th {
  border-color: hsla(0, 0%, 100%, 0.08);
}
td .label,
td dl,
td form,
td input,
td label,
td p,
td select {
  margin: 0.5em 0;
}
label {
  color: #222;
}
.table {
  display: table;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 849px) {
  .touch-scroll-table {
    box-shadow: inset -2px 0 18px -5px rgba(0, 0, 0, 0.5);
    margin-left: -15px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 15px;
    position: relative;
    width: calc(100% + 30px);
  }
  .touch-scroll-table table {
    width: 100%;
  }
}
.text-box {
  max-height: 100%;
}
.text-box .text-box-content {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 10;
}
.text-box-circle .text,
.text-box-square .text {
  background-color: rgba(0, 0, 0, 0.9);
  height: 0;
  padding: 100% 0 0 !important;
}
.text-box-circle .text-inner,
.text-box-square .text-inner {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.text-box-circle .border,
.text-box-circle .text-box-content {
  border-radius: 100% !important;
}
a {
  color: #334862;
  text-decoration: none;
}
.button:focus:not(:focus-visible),
a:focus:not(:focus-visible),
button:focus:not(:focus-visible),
input:focus:not(:focus-visible) {
  outline: none;
}
.button:focus-visible,
a:focus-visible,
button:focus-visible,
input:focus-visible {
  outline: 2px solid currentColor;
  outline-offset: 2px;
}
a.plain {
  color: currentColor;
  transition: color 0.3s, opacity 0.3s, transform 0.3s;
}
.nav-dark a.plain:hover {
  opacity: 0.7;
}
a.icon-circle:hover,
a.remove:hover,
a:hover {
  color: #000;
}
.primary:focus-visible,
.submit-button:focus-visible,
button[type="submit"]:focus-visible {
  outline-color: #446084;
}
.secondary:focus-visible {
  outline-color: #d26e4b;
}
.alt:focus-visible {
  outline-color: #d26e4b;
}
.dark .widget a,
.dark a {
  color: currentColor;
}
.dark .widget a:hover,
.dark a:hover {
  color: #fff;
}
ul.links {
  font-size: 0.85em;
  margin: 5px 0;
}
ul.links li {
  display: inline-block;
  margin: 0 0.4em;
}
ul.links li a {
  color: currentColor;
}
ul.links li:before {
  display: inline-block;
  font-family: fl-icons;
  margin-right: 5px;
}
ul.links li:first-child {
  margin-left: 0;
}
ul.links li:last-child {
  margin-right: 0;
}
.next-prev-nav .flex-col {
  padding: 0.7em 0;
}
.next-prev-nav i {
  font-size: 2em;
  margin: 0 10px !important;
  top: -1px;
  vertical-align: middle;
}
.next-prev-nav .flex-col + .flex-col {
  border-left: 1px solid #ececec;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
ol,
ul {
  margin-top: 0;
  padding: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 1.5em 0 1.5em 3em;
}
li {
  margin-bottom: 0.6em;
}
dl dd,
dl dt {
  display: inline-block;
  margin: 0 5px 0 0;
}
dl dd p,
dl dt p {
  margin: 0;
  padding: 0;
}
dl {
  font-size: 0.85em;
  opacity: 0.8;
  text-transform: uppercase;
}
ul.ul-reset,
ul.ul-reset > li {
  margin: 0;
  padding: 0;
}
ul.ul-reset > li {
  list-style: none;
}
.col-inner ol li,
.col-inner ul li,
.entry-content ol li,
.entry-content ul li,
.entry-summary ol li,
.entry-summary ul li {
  margin-left: 1.3em;
}
.col-inner ol li.tab,
.col-inner ul li.tab,
.entry-content ol li.tab,
.entry-content ul li.tab,
.entry-summary ol li.tab,
.entry-summary ul li.tab {
  margin-left: 0;
}
ul li.bullet-arrow,
ul li.bullet-checkmark,
ul li.bullet-star {
  border-bottom: 1px solid #ececec;
  list-style: none;
  margin: 0;
  padding: 7px 0 7px 25px;
  position: relative;
}
.dark ul li.bullet-arrow,
.dark ul li.bullet-checkmark,
.dark ul li.bullet-star {
  border-color: hsla(0, 0%, 100%, 0.2);
}
ul li.bullet-arrow:before,
ul li.bullet-checkmark:before,
ul li.bullet-star:before {
  color: #7a9c59;
  font-family: fl-icons;
  font-size: 16px;
  left: 0;
  position: absolute;
}
ul li.bullet-checkmark:before {
  content: "" !important;
}
ul li.bullet-arrow:before {
  content: "" !important;
}
ul li.bullet-star:before {
  content: "" !important;
}
.button,
button,
fieldset,
input,
select,
textarea {
  margin-bottom: 1em;
}
blockquote,
dl,
figure,
form,
ol,
p,
pre,
ul {
  margin-bottom: 1.3em;
}
form p {
  margin-bottom: 0.5em;
}
body {
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeSpeed;
  color: #555;
  margin-bottom: 0.5em;
  margin-top: 0;
  width: 100%;
}
.h1,
h1 {
  font-size: 1.7em;
}
.h1,
.h2,
h1,
h2 {
  line-height: 1.3;
}
.h2,
h2 {
  font-size: 1.6em;
}
.h3,
h3 {
  font-size: 1.25em;
}
.h4,
h4 {
  font-size: 1.125em;
}
.h5,
h5 {
  font-size: 1em;
}
.h6,
h6 {
  font-size: 0.85em;
  opacity: 0.8;
}
h1.entry-title.mb {
  margin-bottom: 30px;
}
@media (max-width: 549px) {
  h1 {
    font-size: 1.4em;
  }
  h2 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1em;
  }
}
h6 span {
  font-weight: 400;
  font-weight: 500;
}
h3 label {
  display: inline-block;
  margin-right: 10px;
}
p {
  margin-top: 0;
}
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span,
p.headline > span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
a.lead,
p.lead {
  font-size: 1.3em;
}
.uppercase,
h6,
span.widget-title,
th {
  letter-spacing: 0.05em;
  line-height: 1.05;
  text-transform: uppercase;
}
.lowercase {
  text-transform: none !important;
}
span.widget-title {
  font-size: 1em;
  font-weight: 600;
}
.is-normal {
  font-weight: 400;
}
.is-bold {
  font-weight: 700;
}
.is-thin,
.thin-font {
  font-weight: 300 !important;
}
.is-thin strong,
.thin-font strong {
  font-weight: 700 !important;
}
.is-italic {
  font-style: italic !important;
}
.is-uppercase,
.uppercase {
  line-height: 1.2;
  text-transform: uppercase;
}
.alt-font,
.is-alt-font {
  font-family: Georgia;
}
.is-xxxlarge {
  font-size: 2.5em;
}
.is-xxlarge {
  font-size: 2em;
}
.is-xlarge {
  font-size: 1.5em;
}
.is-larger {
  font-size: 1.3em;
}
.is-large {
  font-size: 1.15em;
}
.is-small,
.is-small.button {
  font-size: 0.8em;
}
.is-smaller {
  font-size: 0.75em;
}
.is-xsmall {
  font-size: 0.7em;
}
.is-xxsmall {
  font-size: 0.6em;
}
@media (max-width: 549px) {
  .is-xxlarge {
    font-size: 2.5em;
  }
  .is-xlarge {
    font-size: 1.8em;
  }
  .is-larger {
    font-size: 1.2em;
  }
  .is-large {
    font-size: 1em;
  }
}
.box-text a:not(.button),
.box-text h1,
.box-text h2,
.box-text h3,
.box-text h4,
.box-text h5,
.box-text h6 {
  line-height: 1.3;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}
.box-text p {
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}
.box-text .button {
  margin-top: 1em;
}
.banner .button {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.banner .is-divider,
.banner .text-center .is-divider {
  margin-bottom: 1em;
  margin-top: 1em;
}
.banner h1 {
  font-size: 3.5em;
}
.banner h2 {
  font-size: 2.9em;
}
.banner h3 {
  font-size: 2em;
}
.banner h1,
.banner h2,
.banner h3 {
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  padding: 0;
}
.banner h4 {
  font-size: 1.5em;
  margin-bottom: 0.6em;
  margin-top: 0.6em;
  padding: 0;
}
.banner h5,
.banner h6,
.banner p {
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 0;
}
.line-height-small {
  line-height: 1.4em;
}
[data-line-height="xs"] {
  line-height: 0.85 !important;
}
[data-line-height="s"] {
  line-height: 1 !important;
}
[data-line-height="m"] {
  line-height: 1.2 !important;
}
[data-line-height="l"] {
  line-height: 1.4 !important;
}
[data-line-height="xl"] {
  line-height: 1.6 !important;
}
.nav > li > a {
  font-size: 0.8em;
}
.nav > li.html {
  font-size: 0.85em;
}
.nav-size-xsmall > li > a {
  font-size: 0.65em;
}
.nav-size-small > li > a {
  font-size: 0.7em;
}
.nav-size-medium > li > a {
  font-size: 0.9em;
}
.nav-size-large > li > a {
  font-size: 1em;
}
.nav-size-xlarge > li > a {
  font-size: 1.1em;
}
.nav-spacing-xsmall > li {
  margin: 0;
}
.nav-spacing-small > li {
  margin: 0 5px;
}
.nav-spacing-medium > li {
  margin: 0 9px;
}
.nav-spacing-large > li {
  margin: 0 11px;
}
.nav-spacing-xlarge > li {
  margin: 0 13px;
}
.fancy-underline {
  position: relative;
}
.fancy-underline:after {
  /* background: url(../img/underline.png) 50% top no-repeat; */
  background-size: 100% 9px;
  border-radius: 999px;
  color: green;
  content: "";
  height: 9px;
  image-rendering: -webkit-optimize-contrast;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}
span.count-up {
  opacity: 0;
  transition: opacity 2s;
}
span.count-up.active {
  opacity: 1;
}
[data-text-color="primary"] {
  color: #446084 !important;
}
[data-text-color="secondary"] {
  color: #d26e4b !important;
}
[data-text-color="alert"] {
  color: #b20000 !important;
}
[data-text-color="success"] {
  color: #7a9c59 !important;
}
[data-text-bg="primary"] {
  background-color: #446084;
}
[data-text-bg="secondary"] {
  background-color: #d26e4b;
}
[data-text-bg="alert"] {
  background-color: #b20000;
}
[data-text-bg="success"] {
  background-color: #7a9c59;
}
.text-bordered-dark,
.text-bordered-primary,
.text-bordered-white {
  border: 2px solid #fff;
  display: inline-block;
  padding: 5px;
}
.text-bordered-dark {
  border-color: #000;
}
h1.text-bordered-dark,
h1.text-bordered-primary,
h1.text-bordered-white {
  padding: 15px;
}
.text-boarder-top-bottom-dark,
.text-boarder-top-bottom-white {
  border-bottom: 3px solid #fff;
  border-top: 3px solid #fff;
  display: inline-block;
  padding-bottom: 15px;
  padding-top: 15px;
}
.text-boarder-top-bottom-dark {
  border-color: #000;
}
blockquote {
  border-left: 2px solid #446084;
  color: #333;
  font-size: 1.2em;
  font-style: italic;
  margin: 0 0 1.25em;
  padding: 0 1.25em 0 1.875em;
  position: relative;
}
.dark blockquote {
  color: #fff;
}
.clear:after,
.clearfix:after,
.container:after,
.row:after {
  clear: both;
  content: "";
  display: table;
}
@media (max-width: 549px) {
  .hide-for-small,
  [data-show="hide-for-small"] {
    display: none !important;
  }
  .small-text-center {
    float: none !important;
    text-align: center !important;
    width: 100% !important;
  }
}
@media (min-width: 550px) {
  .show-for-small,
  [data-show="show-for-small"] {
    display: none !important;
  }
}
@media (min-width: 850px) {
  .show-for-medium,
  [data-show="show-for-medium"] {
    display: none !important;
  }
}
@media (max-width: 849px) {
  .hide-for-medium,
  [data-show="hide-for-medium"] {
    display: none !important;
  }
  .medium-text-center .pull-left,
  .medium-text-center .pull-right {
    float: none;
  }
  .medium-text-center .ml {
    margin-left: auto;
  }
  .medium-text-center .mr {
    margin-right: auto;
  }
  .medium-text-center {
    float: none !important;
    text-align: center !important;
    width: 100% !important;
  }
}
@media (min-width: 850px) {
  .hide-for-large,
  [data-show="hide-for-large"] {
    display: none !important;
  }
}
.expand,
.full-width {
  display: block;
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}
.pull-right {
  margin-right: 0 !important;
}
.pull-left {
  margin-left: 0 !important;
}
.mb {
  margin-bottom: 30px;
}
.mt {
  margin-top: 30px;
}
.mr {
  margin-right: 30px;
}
.ml {
  margin-left: 30px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-half {
  margin-bottom: 15px;
}
.mt-half {
  margin-top: 15px;
}
.mr-half {
  margin-right: 15px;
}
.ml-half {
  margin-left: 15px;
}
.mb-half:last-child,
.mb:last-child {
  margin-bottom: 0;
}
.pb {
  padding-bottom: 30px;
}
.pt {
  padding-top: 30px;
}
.pb-half {
  padding-bottom: 15px;
}
.pt-half {
  padding-top: 15px;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.inner-padding {
  padding: 30px;
}
.first-reset :first-child {
  margin-top: 0;
}
.last-reset :last-child {
  margin-bottom: 0;
}
.no-select {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-center .is-divider,
.text-center .is-star-rating,
.text-center .star-rating,
.text-center > div,
.text-center > div > div {
  margin-left: auto;
  margin-right: auto;
}
.text-center .pull-left,
.text-center .pull-right {
  float: none;
}
.text-left .is-divider,
.text-left .is-star-rating,
.text-left .star-rating {
  margin-left: 0;
  margin-right: auto;
}
.text-right .is-divider,
.text-right .is-star-rating,
.text-right .star-rating,
.text-right > div,
.text-right > div > div {
  margin-left: auto;
  margin-right: 0;
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.fixed {
  position: fixed !important;
  z-index: 12;
}
.top {
  top: 0;
}
.right {
  right: 0;
}
.left {
  left: 0;
}
.bottom,
.fill {
  bottom: 0;
}
.fill {
  height: 100%;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}
.v-center {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.h-center {
  left: 50%;
  transform: translateX(-50%);
}
.h-center.v-center {
  bottom: auto !important;
  left: auto !important;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.is-full-height {
  height: 100%;
  height: 100vh;
  min-height: 550px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.bg-fill {
  background-position: 50% 50%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.bg-top {
  background-position: top;
  -o-object-position: top;
  object-position: top;
}
.circle,
.circle img {
  border-radius: 999px !important;
  -o-object-fit: cover;
  object-fit: cover;
}
.round {
  border-radius: 5px;
}
.has-border {
  border: 2px solid #446084;
  padding: 15px 30px 30px;
}
.dashed-border {
  border-style: dashed;
}
.success-border {
  border-color: #7a9c59;
}
.bt {
  border-top: 1px solid #ececec;
}
.bb {
  border-bottom: 1px solid #ececec;
}
.bl {
  border-left: 1px solid #ececec;
}
.br {
  border-right: 1px solid #ececec;
}
.is-invisible {
  opacity: 0;
  visibility: hidden;
}
.z-1 {
  z-index: 21;
}
.z-2 {
  z-index: 22;
}
.z-3 {
  z-index: 23;
}
.z-4 {
  z-index: 24;
}
.z-5 {
  z-index: 25;
}
.z-top {
  z-index: 9995;
}
.z-top-2 {
  z-index: 9996;
}
.z-top-3 {
  z-index: 9997;
}
.no-click {
  pointer-events: none;
}
.no-wrap {
  white-space: nowrap;
}
.primary-color {
  color: #446084;
}
.secondary-color {
  color: #d26e4b;
}
.success-color {
  color: #7a9c59;
}
.alert-color {
  color: #b20000;
}
.bg-primary-color {
  background-color: #446084;
}
.bg-secondary-color {
  background-color: #d26e4b;
}
.bg-success-color {
  background-color: #7a9c59;
}
.bg-alert-color {
  background-color: #b20000;
}
.is-transparent {
  background-color: transparent !important;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
@media (max-width: 549px) {
  .small-block {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.inline-images img,
img.inline {
  display: inline-block !important;
}
.is-well {
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.07),
    inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.no-overflow {
  overflow: hidden;
}
.no-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.strong {
  font-weight: 700;
}
.op-4 {
  opacity: 0.4;
}
.op-5 {
  opacity: 0.5;
}
.op-6 {
  opacity: 0.6;
}
.op-7 {
  opacity: 0.7;
}
.op-8 {
  opacity: 0.8;
}
@media (max-width: 549px) {
  .sm-touch-scroll {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: none;
  }
}
.no-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.screen-reader-text:focus {
  clip: auto !important;
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
@media screen and (max-width: 549px) {
  .x5 {
    left: 5%;
  }
  .x15 {
    left: 15%;
  }
  .x25 {
    left: 25%;
  }
  .x35 {
    left: 35%;
  }
  .x45 {
    left: 45%;
  }
  .x55 {
    right: 45%;
  }
  .x65 {
    right: 35%;
  }
  .x75 {
    right: 25%;
  }
  .x85 {
    right: 15%;
  }
  .x95 {
    right: 5%;
  }
  .x0 {
    left: 0;
  }
  .x10 {
    left: 10%;
  }
  .x20 {
    left: 20%;
  }
  .x30 {
    left: 30%;
  }
  .x40 {
    left: 40%;
  }
  .x60 {
    right: 40%;
  }
  .x70 {
    right: 30%;
  }
  .x80 {
    right: 20%;
  }
  .x90 {
    right: 10%;
  }
  .x100 {
    right: 0;
  }
  .y0 {
    top: 0;
  }
  .y10 {
    top: 10%;
  }
  .y20 {
    top: 20%;
  }
  .y30 {
    top: 30%;
  }
  .y40 {
    top: 40%;
  }
  .y60 {
    bottom: 40%;
  }
  .y70 {
    bottom: 30%;
  }
  .y80 {
    bottom: 20%;
  }
  .y90 {
    bottom: 10%;
  }
  .y100 {
    bottom: 0;
  }
  .y5 {
    top: 5%;
  }
  .y15 {
    top: 15%;
  }
  .y25 {
    top: 25%;
  }
  .y35 {
    top: 35%;
  }
  .y45 {
    top: 45%;
  }
  .y55 {
    bottom: 45%;
  }
  .y65 {
    bottom: 35%;
  }
  .y75 {
    bottom: 25%;
  }
  .y85 {
    bottom: 15%;
  }
  .y95 {
    bottom: 5%;
  }
  .x50 {
    left: 50%;
    transform: translateX(-50%);
  }
  .y50 {
    top: 50.3%;
    transform: translateY(-50%);
  }
  .y50.x50 {
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 550px) and (max-width: 849px) {
  .md-x5 {
    left: 5%;
  }
  .md-x15 {
    left: 15%;
  }
  .md-x25 {
    left: 25%;
  }
  .md-x35 {
    left: 35%;
  }
  .md-x45 {
    left: 45%;
  }
  .md-x55 {
    right: 45%;
  }
  .md-x65 {
    right: 35%;
  }
  .md-x75 {
    right: 25%;
  }
  .md-x85 {
    right: 15%;
  }
  .md-x95 {
    right: 5%;
  }
  .md-y5 {
    top: 5%;
  }
  .md-y15 {
    top: 15%;
  }
  .md-y25 {
    top: 25%;
  }
  .md-y35 {
    top: 35%;
  }
  .md-y45 {
    top: 45%;
  }
  .md-y55 {
    bottom: 45%;
  }
  .md-y65 {
    bottom: 35%;
  }
  .md-y75 {
    bottom: 25%;
  }
  .md-y85 {
    bottom: 15%;
  }
  .md-y95 {
    bottom: 5%;
  }
  .md-x0 {
    left: 0;
  }
  .md-x10 {
    left: 10%;
  }
  .md-x20 {
    left: 20%;
  }
  .md-x30 {
    left: 30%;
  }
  .md-x40 {
    left: 40%;
  }
  .md-x60 {
    right: 40%;
  }
  .md-x70 {
    right: 30%;
  }
  .md-x80 {
    right: 20%;
  }
  .md-x90 {
    right: 10%;
  }
  .md-x100 {
    right: 0;
  }
  .md-y0 {
    top: 0;
  }
  .md-y10 {
    top: 10%;
  }
  .md-y20 {
    top: 20%;
  }
  .md-y30 {
    top: 30%;
  }
  .md-y40 {
    top: 40%;
  }
  .md-y60 {
    bottom: 40%;
  }
  .md-y70 {
    bottom: 30%;
  }
  .md-y80 {
    bottom: 20%;
  }
  .md-y90 {
    bottom: 10%;
  }
  .md-y100 {
    bottom: 0;
  }
  .md-x50 {
    left: 50%;
    transform: translateX(-50%) !important;
  }
  .md-y50 {
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .md-x50.md-y50 {
    transform: translate(-50%, -50%) !important;
  }
}
@media screen and (min-width: 850px) {
  .lg-x5 {
    left: 5%;
  }
  .lg-x15 {
    left: 15%;
  }
  .lg-x25 {
    left: 25%;
  }
  .lg-x35 {
    left: 35%;
  }
  .lg-x45 {
    left: 45%;
  }
  .lg-x55 {
    right: 45%;
  }
  .lg-x65 {
    right: 35%;
  }
  .lg-x75 {
    right: 25%;
  }
  .lg-x85 {
    right: 15%;
  }
  .lg-x95 {
    right: 5%;
  }
  .lg-y5 {
    top: 5%;
  }
  .lg-y15 {
    top: 15%;
  }
  .lg-y25 {
    top: 25%;
  }
  .lg-y35 {
    top: 35%;
  }
  .lg-y45 {
    top: 45%;
  }
  .lg-y55 {
    bottom: 45%;
  }
  .lg-y65 {
    bottom: 35%;
  }
  .lg-y75 {
    bottom: 25%;
  }
  .lg-y85 {
    bottom: 15%;
  }
  .lg-y95 {
    bottom: 5%;
  }
  .lg-x0 {
    left: 0;
  }
  .lg-x10 {
    left: 10%;
  }
  .lg-x20 {
    left: 20%;
  }
  .lg-x30 {
    left: 30%;
  }
  .lg-x40 {
    left: 40%;
  }
  .lg-x60 {
    right: 40%;
  }
  .lg-x70 {
    right: 30%;
  }
  .lg-x80 {
    right: 20%;
  }
  .lg-x90 {
    right: 10%;
  }
  .lg-x100 {
    right: 0;
  }
  .lg-y0 {
    top: 0;
  }
  .lg-y10 {
    top: 10%;
  }
  .lg-y20 {
    top: 20%;
  }
  .lg-y30 {
    top: 30%;
  }
  .lg-y40 {
    top: 40%;
  }
  .lg-y60 {
    bottom: 40%;
  }
  .lg-y70 {
    bottom: 30%;
  }
  .lg-y80 {
    bottom: 20%;
  }
  .lg-y90 {
    bottom: 10%;
  }
  .lg-y100 {
    bottom: 0;
  }
  .lg-x50 {
    left: 50%;
    transform: translateX(-50%) !important;
  }
  .lg-y50 {
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .lg-x50.lg-y50 {
    transform: translate(-50%, -50%) !important;
  }
}
.res-text {
  font-size: 11px;
}
.small-1 .res-text,
.small-2 .res-text,
.small-3 .res-text,
.widget-area .res-text {
  font-size: 8px;
}
.small-1 .res-text h6,
.small-2 .res-text h6,
.small-3 .res-text h6 {
  font-size: 10px;
}
.small-1 .res-text p.lead,
.small-2 .res-text p.lead,
.small-3 .res-text p.lead {
  font-size: 16px;
}
.small-4 .res-text {
  font-size: 8.5px;
}
.small-5 .res-text {
  font-size: 9px;
}
.small-6 .res-text {
  font-size: 10px;
}
.small-7 .res-text {
  font-size: 11px;
}
.small-8 .res-text {
  font-size: 12px;
}
.small-9 .res-text {
  font-size: 13px;
}
.small-10 .res-text {
  font-size: 14px;
}
.small-11 .res-text {
  font-size: 15px;
}
@media screen and (min-width: 550px) {
  .medium-1 .res-text,
  .medium-2 .res-text,
  .medium-3 .res-text,
  .medium-4 .res-text,
  .medium-5 .res-text,
  .medium-6 .res-text,
  .medium-7 .res-text {
    font-size: 0.8vw;
  }
  .medium-8 .res-text {
    font-size: 0.9vw;
  }
  .medium-9 .res-text {
    font-size: 1.15vw;
  }
  .medium-10 .res-text {
    font-size: 1.3vw;
  }
  .medium-11 .res-text {
    font-size: 1.5vw;
  }
  .res-text {
    font-size: 1.8vw;
  }
}
@media screen and (min-width: 850px) {
  .res-text {
    font-size: 16px;
  }
  .large-1 .res-text,
  .large-2 .res-text,
  .large-3 .res-text,
  .widget-area .res-text {
    font-size: 8px;
  }
  .large-1 .res-text h6,
  .large-2 .res-text h6,
  .large-3 .res-text h6 {
    font-size: 10px;
  }
  .large-1 .res-text p.lead,
  .large-2 .res-text p.lead,
  .large-3 .res-text p.lead {
    font-size: 16px;
  }
  .large-4 .res-text {
    font-size: 8.5px;
  }
  .large-5 .res-text {
    font-size: 9px;
  }
  .large-6 .res-text {
    font-size: 10px;
  }
  .large-7 .res-text {
    font-size: 11px;
  }
  .large-8 .res-text {
    font-size: 12px;
  }
  .large-9 .res-text {
    font-size: 13px;
  }
  .large-10 .res-text {
    font-size: 14px;
  }
  .large-11 .res-text {
    font-size: 15px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .slider [data-animate],
  [data-animate] {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-backface-visibility: hidden;
    opacity: 0 !important;
    transition: filter 1.3s, transform 1.6s, opacity 0.7s ease-in;
    will-change: filter, transform, opacity;
  }
  .slider [data-animate="bounceInDown"],
  .slider [data-animate="bounceInLeft"],
  .slider [data-animate="bounceInRight"],
  .slider [data-animate="bounceInUp"],
  [data-animate="bounceInDown"],
  [data-animate="bounceInLeft"],
  [data-animate="bounceInRight"],
  [data-animate="bounceInUp"] {
    transition: transform 0.8s cubic-bezier(0, 0.195, 0, 1.465),
      opacity 0.5s ease-in;
  }
  .slider [data-animate="bounceInLeft"],
  [data-animate="bounceInLeft"] {
    transform: translate3d(-300px, 0, 0);
  }
  .slider [data-animate="blurIn"],
  [data-animate="blurIn"] {
    filter: blur(15px);
  }
  .slider [data-animate="fadeInLeft"],
  [data-animate="fadeInLeft"] {
    transform: translate3d(-70px, 0, 0);
  }
  .slider [data-animate="fadeInRight"],
  [data-animate="fadeInRight"] {
    transform: translate3d(70px, 0, 0);
  }
  .slider [data-animate="bounceInUp"],
  .slider [data-animate="fadeInUp"],
  [data-animate="bounceInUp"],
  [data-animate="fadeInUp"] {
    transform: translate3d(0, 70px, 0);
  }
  .slider [data-animate="bounceInRight"],
  [data-animate="bounceInRight"] {
    transform: translate3d(300px, 0, 0);
  }
  .slider [data-animate="bounceIn"],
  [data-animate="bounceIn"] {
    transform: scale(1.3);
  }
  .slider [data-animate="bounceInDown"],
  .slider [data-animate="fadeInDown"],
  [data-animate="bounceInDown"],
  [data-animate="fadeInDown"] {
    transform: translate3d(0, -70px, 0);
  }
  .slider [data-animate="flipInY"],
  [data-animate="flipInY"] {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transform: perspective(400px) rotateY(-90deg) translate3d(15px, 0, 0);
  }
  .slider [data-animate="flipInX"],
  [data-animate="flipInX"] {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transform: perspective(400px) rotateX(90deg) translate3d(0, -15px, 0);
  }
  .row-slider.slider [data-animated="true"],
  .slider .is-selected [data-animated="true"],
  [data-animated="true"] {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    opacity: 1 !important;
    transform: translateZ(0) scale(1);
  }
  .flickity-slider > :not(.is-selected) [data-animated="true"] {
    transition: transform 0.7s, opacity 0.3s !important;
  }
  .slider [data-animate="none"],
  [data-animate="none"] {
    opacity: 1 !important;
  }
  .slider [data-animate="blurIn"][data-animated="true"],
  [data-animated="true"][data-animate="blurIn"] {
    filter: blur(0);
  }
  [data-animated="false"] {
    transition: none !important;
  }
  .has-shadow [data-animate],
  [data-animate]:hover {
    transition-delay: 0s !important;
  }
  [data-animate-delay="100"] {
    transition-delay: 0.1s;
  }
  .nav-anim > li {
    opacity: 0;
    transform: translateY(-15px);
    transition: transform 0.3s, opacity 0.3s;
  }
  .active .nav-anim > li,
  .mfp-ready .nav-anim > li,
  .nav-anim.active > li {
    opacity: 1;
    transform: none;
  }
  .col + .col [data-animate],
  .nav-anim > li,
  [data-animate-delay="200"],
  [data-animate] + [data-animate] {
    transition-delay: 0.2s;
  }
  .nav-anim > li + li,
  [data-animate-delay="300"] {
    transition-delay: 0.3s;
  }
  .col + .col + .col [data-animate],
  .nav-anim > li + li + li,
  [data-animate-delay="400"],
  [data-animate] + [data-animate] + [data-animate] {
    transition-delay: 0.4s;
  }
  .nav-anim > li + li + li + li,
  [data-animate-delay="500"] {
    transition-delay: 0.5s;
  }
  .col + .col + .col + .col [data-animate],
  .nav-anim > li + li + li + li + li,
  [data-animate-delay="600"],
  [data-animate] + [data-animate] + [data-animate] + [data-animate] {
    transition-delay: 0.6s;
  }
  .nav-anim > li + li + li + li + li + li,
  [data-animate-delay="700"] {
    transition-delay: 0.7s;
  }
  .col + .col + .col + .col + .col [data-animate],
  .nav-anim > li + li + li + li + li + li + li,
  [data-animate-delay="800"],
  [data-animate]
    + [data-animate]
    + [data-animate]
    + [data-animate]
    + [data-animate] {
    transition-delay: 0.8s;
  }
  .col + .col + .col + .col + .col + .col [data-animate],
  .nav-anim > li + li + li + li + li + li + li + li,
  [data-animate-delay="900"] {
    transition-delay: 0.9s;
  }
  .col + .col + .col + .col + .col + .col + .col [data-animate],
  .nav-anim > li + li + li + li + li + li + li + li + li,
  [data-animate-delay="1000"] {
    transition-delay: 1s;
  }
  .slider-type-fade .flickity-slider > :not(.is-selected) [data-animate] {
    opacity: 0 !important;
    transition: none !important;
  }
}
@-webkit-keyframes stuckMoveDown {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes stuckMoveDown {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes stuckMoveUp {
  0% {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes stuckMoveUp {
  0% {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes stuckFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes stuckFadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.is-border {
  border: 1px solid #ececec;
  border-radius: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
.is-dashed {
  border-style: dashed;
}
.is-dotted {
  border-style: dotted;
}
.has-shadow .box:not(.box-overlay):not(.box-shade),
.has-shadow.box:not(.box-overlay):not(.box-shade) {
  background-color: #fff;
}
.dark .has-shadow .box:not(.box-overlay):not(.box-shade),
.dark .has-shadow.box:not(.box-overlay):not(.box-shade) {
  background-color: #333;
}
.box-shadow-1,
.box-shadow-1-hover,
.box-shadow-2,
.box-shadow-2-hover,
.box-shadow-3,
.box-shadow-3-hover,
.box-shadow-4,
.box-shadow-4-hover,
.box-shadow-5,
.box-shadow-5-hover,
.row-box-shadow-1 .col-inner,
.row-box-shadow-1-hover .col-inner,
.row-box-shadow-2 .col-inner,
.row-box-shadow-2-hover .col-inner,
.row-box-shadow-3 .col-inner,
.row-box-shadow-3-hover .col-inner,
.row-box-shadow-4 .col-inner,
.row-box-shadow-4-hover .col-inner,
.row-box-shadow-5 .col-inner,
.row-box-shadow-5-hover .col-inner {
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s,
    opacity 0.3s;
}
.box-shadow-1,
.box-shadow-1-hover:hover,
.row-box-shadow-1 .col-inner,
.row-box-shadow-1-hover .col-inner:hover {
  box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.box-shadow,
.box-shadow-2,
.box-shadow-2-hover:hover,
.row-box-shadow-2 .col-inner,
.row-box-shadow-2-hover .col-inner:hover {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.box-shadow-3,
.box-shadow-3-hover:hover,
.row-box-shadow-3 .col-inner,
.row-box-shadow-3-hover .col-inner:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.22);
}
.box-shadow-4,
.box-shadow-4-hover:hover,
.row-box-shadow-4 .col-inner,
.row-box-shadow-4-hover .col-inner:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
}
.box-shadow-5,
.box-shadow-5-hover:hover,
.row-box-shadow-5 .col-inner,
.row-box-shadow-5-hover .col-inner:hover {
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow-4-hover:hover,
.box-shadow-5-hover:hover,
.row-box-shadow-4-hover .col-inner:hover,
.row-box-shadow-5-hover .col-inner:hover {
  transform: translateY(-6px);
}
.text-shadow,
.text-shadow-1 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.text-shadow-2 {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.text-shadow-3 {
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
}
.text-shadow-4 {
  text-shadow: 3px 3px 12px rgba(0, 0, 0, 0.5);
}
.text-shadow-5 {
  text-shadow: 5px 5px 16px rgba(0, 0, 0, 0.6);
}
a.hotspot {
  background-color: #000;
  border-radius: 99px;
  color: #fff;
  display: block;
  font-weight: bolder;
  height: 1.5em;
  line-height: 1.5em;
  opacity: 0.8;
  padding: 0;
  text-align: center;
  transition: all 0.3s;
  width: 1.5em;
}
a.hotspot i {
  font-size: 0.8em;
}
a.hotspot:hover {
  background-color: #fff !important;
  color: #000 !important;
  opacity: 1;
}
.has-hover .bg,
.has-hover [class*="image-"] img {
  transition: filter 0.6s, opacity 0.6s, transform 0.6s, box-shadow 0.3s;
}
.bg-color .bg,
.bg-grayscale:hover .bg,
.has-hover .image-color img,
.has-hover:hover .image-grayscale img {
  filter: grayscale(100%);
}
.bg-color:hover .bg,
.has-hover:hover .image-color img {
  filter: grayscale(0);
}
.bg-zoom:hover .bg,
.has-hover:hover .image-zoom img {
  transform: scale(1.1);
}
.bg-zoom-long .bg,
.has-hover .image-zoom-long img {
  transition: transform 5s !important;
}
.bg-zoom-long .bg,
.has-hover:hover .image-zoom-long img {
  transform: scale(1.3);
}
.bg-zoom-fade:hover .bg,
.has-hover:hover .image-zoom-fade img {
  opacity: 0.7;
  transform: scale(1.1);
}
.bg-glow:hover .bg,
.has-hover:hover .image-glow img {
  filter: brightness(1.1);
}
.bg-fade-out:hover .bg,
.has-hover:hover .image-fade-out img {
  opacity: 0.7;
}
.has-hover:hover .image-fade img,
.image-fade:hover .bg {
  opacity: 0.6;
}
.bg-fade-in .bg,
.image-fade-in img {
  opacity: 0.7;
}
.bg-fade-in:hover .bg,
.has-hover:hover .image-fade-in img {
  opacity: 1;
}
.bg-blur:hover .bg,
.has-hover:hover .image-blur img {
  filter: blur(5px);
}
.bg-overlay-add:not(:hover) .overlay,
.bg-overlay-remove:hover .overlay,
.has-hover:hover .image-overlay-remove .overlay,
.has-hover:not(:hover) .image-overlay-add .overlay {
  opacity: 0;
}
.bg-overlay-add-50:not(:hover) .overlay,
.bg-overlay-remove-50:hover .overlay,
.has-hover:hover .image-overlay-remove-50 .overlay,
.has-hover:not(:hover) .image-overlay-add-50 .overlay {
  opacity: 0.5;
}
.has-mask {
  overflow: hidden;
  transform: translateZ(0);
}
.mask-circle {
  -webkit-clip-path: circle(50% at center);
  clip-path: circle(50% at center);
}
.mask-angled {
  -webkit-clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 100%);
}
.mask-angled-right {
  -webkit-clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);
}
.mask-arrow {
  -webkit-clip-path: polygon(
    0 0,
    calc(50% - 30px) 0,
    50% 30px,
    calc(50% + 30px) 0,
    100% 0,
    100% 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    calc(50% - 30px) 0,
    50% 30px,
    calc(50% + 30px) 0,
    100% 0,
    100% 100%,
    0 100%
  );
}
.mask-angled-large {
  -webkit-clip-path: polygon(0 60px, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 60px, 100% 0, 100% 100%, 0 100%);
}
.mask-angled-right-large {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 60px), 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 60px), 0 100%);
}
.mask-arrow-large {
  -webkit-clip-path: polygon(
    0 0,
    calc(50% - 60px) 0,
    50% 60px,
    calc(50% + 60px) 0,
    100% 0,
    100% 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    calc(50% - 60px) 0,
    50% 60px,
    calc(50% + 60px) 0,
    100% 0,
    100% 100%,
    0 100%
  );
}
.mask-angled,
.mask-angled-right,
.mask-arrow {
  margin-top: -30px;
}
.mask-angled-large,
.mask-angled-right-large,
.mask-arrow-large {
  margin-top: -60px;
}
[data-parallax-container] {
  overflow: hidden;
}
@media (prefers-reduced-motion: no-preference) {
  [data-parallax] {
    will-change: transform !important;
  }
  [data-parallax]:not(.parallax-active),
  [data-parallax]:not(.parallax-active) .bg {
    opacity: 0;
  }
}
.shade {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.2);
  background: linear-gradient(0deg, #323232 0, rgba(50, 50, 50, 0) 33%);
  bottom: 0;
  content: " ";
  left: 0;
  opacity: 0.3;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
}
.shade-top {
  background: rgba(0, 0, 0, 0.2);
  background: linear-gradient(180deg, #000 0, transparent 70%);
  opacity: 0.4;
}
.box:hover .shade {
  opacity: 0.5;
}
.box-shade:not(.dark) .shade {
  background: hsla(0, 0%, 100%, 0.2);
  background: linear-gradient(0deg, #fff 0, hsla(0, 0%, 100%, 0) 33%);
  opacity: 0.8;
}
.hover-slide,
.hover-slide-in,
.hover-zoom,
.hover-zoom-in,
.show-on-hover {
  filter: blur(0);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, transform 0.3s, max-height 0.6s, filter 0.6s;
}
.hover-slide {
  transform: translateY(15%) translateZ(0);
}
.hover-slide-in {
  transform: translateY(100%) translateZ(0);
}
.box-text-middle .box-text.hover-slide {
  transform: translateY(-40%) translateZ(0);
}
.hover-fade-in {
  opacity: 0;
  transition: opacity 0.3s;
}
.has-hover:hover .hover-fade-in {
  opacity: 1;
}
.hover-fade-out {
  transition: opacity 0.3s;
}
.has-hover:hover .hover-fade-out {
  opacity: 0;
}
.hover-invert {
  filter: invert(0);
  opacity: 1 !important;
}
.has-hover:hover .hover-invert {
  filter: invert(100%);
}
.hover-reveal {
  max-height: 0;
  opacity: 0;
  transform: translateY(15%) translateZ(0);
  transition: all 0.6s;
}
.has-hover:hover .hover-reveal {
  max-height: 150px;
  opacity: 1;
}
.has-hover:hover .hover-reveal.reveal-small {
  max-height: 30px;
}
.hover-blur {
  filter: blur(10px);
  opacity: 0;
  transition: filter 0.3s, opacity 0.3s;
}
.has-hover:hover .hover-blur {
  filter: blur(0) !important;
  opacity: 1;
}
.hover-zoom {
  transform: scale(1.1) translateZ(0);
}
.box-text-middle .box-text.hover-zoom {
  transform: scale(1.1) translateZ(0) translateY(-50%);
}
.hover-zoom-in {
  transform: scale(0.9) translateZ(0);
}
.box-text-middle .box-text.hover-zoom-in {
  transform: scale(0.9) translateZ(0) translateY(-50%);
}
.hover-bounce {
  opacity: 1 !important;
  transform: scale(1) translateZ(0) translateY(10px) !important;
  transition: transform 0.3s;
}
.has-hover:hover .hover-bounce {
  transform: scale(1) translateZ(0) translateY(0) !important;
}
.box-text-middle .hover-bounce {
  transform: scale(1) translateZ(0) translateY(-40%) !important;
}
.box-text-middle:hover .box-text.hover-bounce {
  transform: scale(1) translateZ(0) translateY(-50%) !important;
}
.has-hover:hover img.show-on-hover {
  opacity: 1;
}
.has-hover .box-image:focus-within .show-on-hover,
.has-hover:hover .hover-slide,
.has-hover:hover .hover-slide-in,
.has-hover:hover .hover-zoom,
.has-hover:hover .hover-zoom-in,
.has-hover:hover .show-on-hover {
  opacity: 1;
  pointer-events: inherit;
  transform: scale(1) translateZ(0) translateY(0) !important;
}
.box-text-middle:hover .show-on-hover.box-text,
.has-hover:hover .show-on-hover.center {
  transform: scale(1) translateZ(0) translateY(-50%) !important;
}
@media (prefers-reduced-motion) {
  .box-text-middle .box-text.hover-slide,
  .box-text-middle:hover .show-on-hover.box-text,
  .has-hover:hover .hover-bounce,
  .has-hover:hover .hover-slide,
  .has-hover:hover .hover-slide-in,
  .has-hover:hover .hover-zoom,
  .has-hover:hover .hover-zoom-in,
  .has-hover:hover .show-on-hover,
  .has-hover:hover .show-on-hover.center,
  .hover-bounce,
  .hover-reveal,
  .hover-slide,
  .hover-slide-in,
  .hover-zoom,
  .hover-zoom-in {
    transform: translateZ(0);
  }
}
.slider .has-slide-effect {
  background-color: transparent !important;
  overflow: hidden;
}
.slider .has-slide-effect .bg {
  opacity: 0;
  transition: opacity 1s;
}
.slide-zoom-in .bg,
.slide-zoom-in-fast .bg {
  transform: scale(1);
}
.slide-zoom-out .bg,
.slide-zoom-out-fast .bg {
  transform: scale(1.2);
}
.has-slide-effect.is-selected .bg {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s, transform 20s ease-out;
}
.slide-fade-in.is-selected .bg {
  transition: opacity 3.5s;
}
.slide-fade-in-fast.is-selected .bg {
  transition: opacity 1.5s;
}
.slide-zoom-in-fast.is-selected .bg,
.slide-zoom-in.is-selected .bg {
  transform: scale(1.2);
}
.slide-zoom-out-fast.is-selected .bg {
  transition: opacity 1s, transform 5s ease-out;
}
.button.tooltip {
  opacity: 1;
}
.tooltipster-default {
  background: #111;
  border-radius: 5px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s;
}
.tooltipster-default a {
  color: #eee;
}
.tooltipster-default:hover {
  color: #fff;
}
.tooltipster-fade-show {
  opacity: 1;
}
.tooltipster-default .tooltipster-content {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  padding: 8px 10px;
}
.tooltipster-base {
  font-size: 0;
  left: 0;
  line-height: 0;
  overflow: visible;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 9999999;
}
.tooltipster-base .tooltipster-content {
  overflow: hidden;
}
.tooltipster-arrow {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: -1;
}
.tooltipster-arrow span,
.tooltipster-arrow-border {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.tooltipster-arrow-top span,
.tooltipster-arrow-top-left span,
.tooltipster-arrow-top-right span {
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-top: 8px solid;
  bottom: -7px;
}
.tooltipster-arrow-top .tooltipster-arrow-border,
.tooltipster-arrow-top-left .tooltipster-arrow-border,
.tooltipster-arrow-top-right .tooltipster-arrow-border {
  border-left: 9px solid transparent !important;
  border-right: 9px solid transparent !important;
  border-top: 9px solid;
  bottom: -7px;
}
.tooltipster-arrow-bottom span,
.tooltipster-arrow-bottom-left span,
.tooltipster-arrow-bottom-right span {
  border-bottom: 8px solid;
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  top: -7px;
}
.tooltipster-arrow-bottom .tooltipster-arrow-border,
.tooltipster-arrow-bottom-left .tooltipster-arrow-border,
.tooltipster-arrow-bottom-right .tooltipster-arrow-border {
  border-bottom: 9px solid;
  border-left: 9px solid transparent !important;
  border-right: 9px solid transparent !important;
  top: -7px;
}
.tooltipster-arrow-bottom .tooltipster-arrow-border,
.tooltipster-arrow-bottom span,
.tooltipster-arrow-top .tooltipster-arrow-border,
.tooltipster-arrow-top span {
  left: 0;
  margin: 0 auto;
  right: 0;
}
.tooltipster-arrow-bottom-left span,
.tooltipster-arrow-top-left span {
  left: 6px;
}
.tooltipster-arrow-bottom-left .tooltipster-arrow-border,
.tooltipster-arrow-top-left .tooltipster-arrow-border {
  left: 5px;
}
.tooltipster-arrow-bottom-right span,
.tooltipster-arrow-top-right span {
  right: 6px;
}
.tooltipster-arrow-bottom-right .tooltipster-arrow-border,
.tooltipster-arrow-top-right .tooltipster-arrow-border {
  right: 5px;
}
.tooltipster-arrow-left .tooltipster-arrow-border,
.tooltipster-arrow-left span {
  border-bottom: 8px solid transparent !important;
  border-left: 8px solid;
  border-top: 8px solid transparent !important;
  margin-top: -7px;
  right: -7px;
  top: 50%;
}
.tooltipster-arrow-left .tooltipster-arrow-border {
  border-bottom: 9px solid transparent !important;
  border-left: 9px solid;
  border-top: 9px solid transparent !important;
  margin-top: -8px;
}
.tooltipster-arrow-right .tooltipster-arrow-border,
.tooltipster-arrow-right span {
  border-bottom: 8px solid transparent !important;
  border-right: 8px solid;
  border-top: 8px solid transparent !important;
  left: -7px;
  margin-top: -7px;
  top: 50%;
}
.tooltipster-arrow-right .tooltipster-arrow-border {
  border-bottom: 9px solid transparent !important;
  border-right: 9px solid;
  border-top: 9px solid transparent !important;
  margin-top: -8px;
}
.dark,
.dark p,
.dark td {
  color: #f1f1f1;
}
.dark .heading-font,
.dark a.lead,
.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6,
.dark label,
.hover-dark:hover a,
.hover-dark:hover h1,
.hover-dark:hover h2,
.hover-dark:hover h3,
.hover-dark:hover h4,
.hover-dark:hover h5,
.hover-dark:hover h6,
.hover-dark:hover p {
  color: #fff;
}
.dark .product-footer .woocommerce-tabs,
.dark .product-section,
.dark .stars a + a {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.dark .nav-vertical li li.menu-item-has-children > a,
.dark .nav-vertical-fly-out > li > a,
.dark .nav-vertical > li > ul li a,
.dark .nav > li.html,
.dark .nav > li > a,
.nav-dark .is-outline,
.nav-dark .nav > li > a,
.nav-dark .nav > li > button,
.nav-dropdown.dark .nav-column > li > a,
.nav-dropdown.dark > li > a {
  color: hsla(0, 0%, 100%, 0.8);
}
.dark .nav-vertical-fly-out > li > a:hover,
.dark .nav > li.active > a,
.dark .nav > li > a.active,
.dark .nav > li > a:hover,
.nav-dark .nav > li.active > a,
.nav-dark .nav > li.current > a,
.nav-dark .nav > li > a.active,
.nav-dark .nav > li > a:hover,
.nav-dark a.plain:hover,
.nav-dropdown.dark .nav-column > li > a:hover,
.nav-dropdown.dark > li > a:hover {
  color: #fff;
}
.dark.nav-dropdown.nav-dropdown-bold > li.nav-dropdown-col,
.dark.nav-dropdown.nav-dropdown-simple > li.nav-dropdown-col,
.nav-dark .header-divider:after,
.nav-dark .nav-divided > li + li > a:after,
.nav-dark .nav > li.header-divider,
.nav-dropdown.dark .nav-column > li > a,
.nav-dropdown.dark > li > a {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.dark .nav-vertical li li.menu-item-has-children > a,
.nav-dark .header-button,
.nav-dark .nav-vertical li li.menu-item-has-children > a,
.nav-dark .nav > li.html {
  color: #fff;
}
.nav-box a:hover span.amount,
.nav-dark span.amount {
  color: currentColor;
}
html {
  overflow-x: hidden;
}
@media (max-width: 849px) {
  body {
    overflow-x: hidden;
  }
}
#main,
#wrapper {
  background-color: #fff;
  position: relative;
}
#main.dark {
  background-color: #333;
}
.page-wrapper {
  padding-bottom: 30px;
  padding-top: 30px;
}
.page-wrapper-full {
  padding-top: 0;
}
.is-sticky-column {
  will-change: min-height;
}
.is-sticky-column__inner {
  display: flow-root;
  transform: translate(0);
  transform: translateZ(0);
  will-change: position, transform;
}
.ux-body-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility 0.3s, opacity 0.3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 29;
}
.ux-body-overlay--click-active,
.ux-body-overlay--hover-active {
  opacity: 1;
  visibility: visible;
}
.header,
.header-wrapper {
  background-position: 50% 0;
  background-size: cover;
  position: relative;
  transition: background-color 0.3s, opacity 0.3s;
  width: 100%;
  z-index: 30;
}
.header-bg-color {
  background-color: hsla(0, 0%, 100%, 0.9);
}
.header-bottom,
.header-top {
  align-items: center;
  display: flex;
  flex-wrap: no-wrap;
}
.header.has-transparent,
.header.show-on-scroll,
.header.transparent {
  position: absolute;
}
.header.show-on-scroll:not(.stuck),
.header.transparent:not(.stuck) {
  left: auto;
  right: auto;
}
.header.show-on-scroll .header-wrapper {
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
}
.header.show-on-scroll .header-wrapper.stuck {
  opacity: 1;
  visibility: visible;
}
.header.transparent .header-bg-color,
.header.transparent .header-bg-image,
.header.transparent .header-bottom,
.header.transparent .header-wrapper {
  background-color: transparent;
  background-image: none !important;
  box-shadow: none;
}
.header-bg-color,
.header-bg-image {
  background-position: 50% 0;
  transition: background 0.4s;
}
.header-top {
  background-color: #446084;
  min-height: 20px;
  position: relative;
  z-index: 11;
}
.header-main {
  position: relative;
  z-index: 10;
}
.header-bottom {
  min-height: 35px;
  position: relative;
  z-index: 9;
}
.top-divider {
  border-top: 1px solid;
  margin-bottom: -1px;
  opacity: 0.1;
}
.nav-dark .top-divider {
  display: none;
}
.stuck {
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
}
.sticky-jump .stuck {
  -webkit-animation: stuckMoveDown 0.6s;
  animation: stuckMoveDown 0.6s;
}
.sticky-fade .stuck {
  -webkit-animation: stuckFadeIn 0.6s;
  animation: stuckFadeIn 0.6s;
}
.sticky-shrink .stuck .header-main {
  transition: height 0.8s;
}
.stuck .header-inner,
.stuck .logo,
.stuck .logo img {
  margin-top: 0;
  padding-top: 0;
}
.header-wrapper:not(.stuck) .logo img {
  transition: max-height 0.5s;
}
.header.show-on-scroll .hide-for-sticky,
.stuck .hide-for-sticky {
  display: none;
}
.header-shadow .header-wrapper,
.header-wrapper.stuck,
.layout-shadow #wrapper {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
}
.nav > li.header-divider {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 30px;
  margin: 0 7.5px;
  position: relative;
  vertical-align: middle;
}
.nav-dark .nav > li.header-divider {
  border-color: hsla(0, 0%, 100%, 0.1);
}
.header-block {
  min-height: 15px;
  width: 100%;
}
.header-full-width .container {
  max-width: 100% !important;
}
@media (max-width: 849px) {
  .header-main li.html.custom {
    display: none;
  }
}
@media (prefers-reduced-motion) {
  .sticky-jump .stuck {
    -webkit-animation-name: stuckFadeIn;
    animation-name: stuckFadeIn;
  }
}
@media (max-width: 549px) {
  .nav-mobile > li > a:not(.button) {
    line-height: 5em;
  }
}
.post {
  margin: 0 0 30px;
}
.masonery .post {
  margin-bottom: 0;
}
.entry-header-text {
  padding: 1.5em 0;
}
.entry-header-text.text-center {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.entry-header-text-top {
  padding-top: 0;
}
.entry-header-text-bottom {
  padding-bottom: 0;
}
.entry-content {
  padding-bottom: 1.5em;
  padding-top: 1.5em;
}
.masonery .article-inner .box {
  margin-bottom: 1.5em;
}
.article-inner {
  transition: opacity 0.3s, box-shadow 0.5s, transform 0.3s;
}
.blog-featured-title + #main .post-sidebar {
  padding-top: 30px;
}
.from_the_blog_comments {
  opacity: 0.8;
  padding-top: 7px;
}
.article-inner.has-shadow {
  background-color: #fff;
}
.article-inner.has-shadow .author-box,
.article-inner.has-shadow .entry-content,
.article-inner.has-shadow .entry-header-text,
.article-inner.has-shadow footer.entry-meta {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.article-inner.has-shadow .entry-header-text-top {
  padding-top: 1.5em;
}
.entry-author {
  padding: 2em 0;
}
.banner h2.entry-title {
  font-size: 2em;
}
.badge.post-date {
  top: 7%;
}
.entry-image img {
  width: 100%;
}
.entry-image-float {
  position: relative;
}
@media (min-width: 850px) {
  .entry-image-float {
    float: left;
    margin-right: 2em;
    max-width: 50%;
  }
  .entry-image-float + .entry-content {
    padding-top: 0;
  }
}
.post .entry-summary p:last-of-type {
  margin-bottom: 0;
}
footer.entry-meta {
  border-bottom: 2px solid #ececec;
  border-top: 1px solid #ececec;
  font-size: 0.8em;
  padding: 0.5em 0 0.6em;
}
li.recentcomments {
  padding: 7px 0;
}
li.recentcomments a {
  display: inline !important;
}
.box-blog-post .is-divider {
  height: 2px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.bypostauthor {
  display: block;
}
.more-link {
  margin-top: 1.5em;
}
.updated:not(.published) {
  display: none;
}
@media (min-width: 850px) {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
  }
  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
  }
  .aligncenter {
    clear: both;
    display: block;
    margin: 0 auto;
  }
}
.wp-caption {
  margin-bottom: 2em;
  max-width: 100%;
}
.wp-caption .wp-caption-text {
  background: rgba(0, 0, 0, 0.05);
  font-size: 0.9em;
  font-style: italic;
  padding: 0.4em;
}
.wp-caption-text {
  text-align: center;
}
img.size-full.alignnone {
  margin-bottom: 2em;
}
.row .entry-content .gallery {
  padding: 0;
}
.gallery-caption {
  display: block;
}
.comment-content .wp-smiley,
.entry-content .wp-smiley,
.page-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}
.widget {
  margin-bottom: 1.5em;
}
.widget ul {
  margin: 0;
}
.widget li {
  list-style: none;
}
.widgets-boxed .widget,
.widgets-framed .widget {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 20px;
}
.widgets-boxed .widget h3 {
  background-color: #446084;
  color: #fff;
  margin-left: -21px;
  margin-top: -16px;
  padding: 10px 20px;
  width: calc(100% + 42px);
}
.widgets-boxed h3 + .is-divider {
  display: none;
}
.widget > ul > li,
ul.menu > li {
  list-style: none;
  margin: 0;
  text-align: left;
}
.widget > ul > li li,
ul.menu > li li {
  list-style: none;
}
.widget > ul > li > a,
.widget > ul > li > span:first-child,
ul.menu > li > a,
ul.menu > li > span:first-child {
  display: inline-block;
  flex: 1;
  padding: 6px 0;
}
.widget > ul > li:before,
ul.menu > li:before {
  display: inline-block;
  font-family: fl-icons;
  margin-right: 6px;
  opacity: 0.6;
}
.widget > ul > li li > a,
ul.menu > li li > a {
  display: inline-block;
  font-size: 0.9em;
  padding: 3px 0;
}
.widget > ul > li + li,
ul.menu > li + li {
  border-top: 1px solid #ececec;
}
.dark .widget > ul > li + li,
.dark ul.menu > li + li {
  border-color: hsla(0, 0%, 100%, 0.08);
}
.widget > ul > li ul,
ul.menu > li ul {
  border-left: 1px solid #ddd;
  display: none;
  margin: 0 0 10px 3px;
  padding-left: 15px;
  width: 100%;
}
.widget > ul > li ul li,
ul.menu > li ul li {
  border: 0;
  margin: 0;
}
.dark .widget > ul > li ul,
.dark ul.menu > li ul {
  border-color: hsla(0, 0%, 100%, 0.08);
}
.dark .widget > ul > li.active > a,
.dark .widget > ul > li.current-cat > a,
.dark .widget > ul > li > a:hover,
.dark ul.menu > li.active > a,
.dark ul.menu > li.current-cat > a,
.dark ul.menu > li > a:hover {
  color: #fff;
}
.widget > ul > li.has-child,
ul.menu > li.has-child {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.widget > ul > li .count {
  font-size: 0.7em;
  line-height: 3em;
  opacity: 0.5;
}
.widget .current-cat-parent > ul,
.widget .current > ul {
  display: block;
}
.widget .current-cat > a {
  color: #000;
  font-weight: bolder;
}
.recent-blog-posts {
  padding: 10px 0;
}
.recent-blog-posts a {
  display: block;
  line-height: 1.2;
  margin-bottom: 6px;
}
.widget .instagram-pics:after {
  clear: both;
  content: "";
  display: table;
}
.widget .instagram-pics > li {
  border: 0;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 33.333333%;
}
.widget .instagram-pics > li a {
  display: block;
  margin: 0;
  padding: 0;
}
.widget .instagram-pics > li img {
  display: block;
  width: 100%;
}
.widget_layered_nav li {
  align-items: center;
  display: flex;
}
.widget_display-latest-tweets li {
  font-size: 0.9em;
  font-style: italic;
}
.widget_display-latest-tweets a {
  display: inline !important;
}
.widget_display-latest-tweets span {
  display: block;
  font-weight: bolder;
}
.wpcf7 {
  color: #333;
  position: relative;
}
.wpcf7 .ajax-loader {
  display: none !important;
}
.dark .wpcf7 {
  color: #fff;
}
.wpcf7 p {
  margin: 0;
  padding: 0;
}
.wpcf7 br {
  display: none;
}
.wpcf7 label {
  display: inline-block;
  width: 100%;
}
.wpcf7 span.wpcf7-list-item {
  margin-left: 0;
  margin-right: 1.2em;
}
.wpcf7 .wpcf7-form-control-wrap {
  display: block;
}
.wpcf7 .ajax-loader {
  background-color: #fff;
  border-radius: 99px;
  position: absolute;
  right: 0;
}
.wpcf7 .wpcf7-not-valid-tip {
  background-color: #f1f1f1;
  border-radius: 3px;
  color: #b20000;
  line-height: 1.2em;
  margin-top: -10px;
  opacity: 0.8;
  padding: 5px 8px;
  position: relative;
}
.wpcf7 .wpcf7-not-valid-tip:after {
  border: 10px solid rgba(136, 183, 213, 0);
  border-bottom-color: #f1f1f1;
  bottom: 100%;
  content: " ";
  height: 0;
  left: 30px;
  margin-left: -10px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.wpcf7 .wpcf7-validation-errors {
  display: none !important;
}
.wpcf7 .flex-col .wpcf7-not-valid-tip {
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 99;
}
.wpcf7 .wpcf7-response-output {
  border-radius: 10px;
  margin: 5px 0 0;
}
.wpcf7-form .processing * {
  opacity: 0.8;
}
.password-required form.post-password-form {
  margin: 0 auto;
  max-width: 620px;
  padding: 30px 15px;
}
.portfolio-inner img {
  width: 100%;
}
.portfolio-bottom {
  margin-bottom: -1px;
}
.row + .portfolio-related .portfolio-element-wrapper {
  margin-top: 30px;
}
.accordion-inner {
  display: none;
  padding: 1em 2.3em;
}
.accordion-title {
  border-top: 1px solid #ddd;
  display: block;
  font-size: 110%;
  padding: 0.6em 2.3em;
  position: relative;
  transition: border 0.2s ease-out, background-color 0.3s;
}
.dark .accordion-title {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.accordion-title.active {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: #446084;
  color: #446084;
  font-weight: 700;
}
.dark .accordion-title.active {
  color: #fff;
}
.accordion .toggle {
  border-radius: 999px;
  height: 1.5em;
  left: 0;
  margin-right: 5px;
  position: absolute;
  top: 0.3em;
  transform-origin: 50% 47%;
  width: 2.3em;
}
.accordion .active .toggle {
  color: currentColor;
  opacity: 1;
}
.breadcrumbs {
  color: #222;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0;
}
.product-info .breadcrumbs {
  font-size: 0.85em;
  margin: 0 0 0.5em;
}
.breadcrumbs .divider,
.breadcrumbs .separator {
  font-weight: 300;
  margin: 0 0.3em;
  opacity: 0.35;
  position: relative;
  top: 0;
}
.dark .breadcrumbs {
  color: #fff;
}
.breadcrumbs a {
  color: hsla(0, 0%, 40%, 0.7);
  font-weight: 400;
}
.breadcrumbs a:first-of-type {
  margin-left: 0;
}
.breadcrumbs a.current,
.breadcrumbs a:hover {
  color: #111;
}
.dark .breadcrumbs a {
  color: #fff;
}
.checkout-breadcrumbs {
  padding: 15px 0;
}
.checkout-breadcrumbs a {
  align-items: center;
  color: #ccc;
  display: inline-flex;
}
.checkout-breadcrumbs .divider {
  margin: 0 0.5em;
  top: 2px;
}
.breadcrumb-step {
  background-color: #ccc;
  border-radius: 99px;
  color: #fff;
  display: inline-block;
  font-size: 0.7em;
  font-weight: bolder;
  height: 1.5em;
  line-height: 1.5em;
  margin: 0 0.5em;
  position: relative;
  text-align: center;
  top: 1px;
  width: 1.5em;
}
.current .breadcrumb-step {
  background-color: #d26e4b;
}
@media (min-width: 850px) {
  .comment-form {
    display: flex;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .comment-form > div,
  .comment-form > p {
    padding: 0 15px;
    width: 100%;
  }
  .comment-form p.comment-form-author,
  .comment-form p.comment-form-email,
  .comment-form p.comment-form-url {
    flex: 1;
  }
}
.comment-inner:target {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 15px;
}
.comment-list li,
.commentlist li {
  list-style: none;
  padding: 1em 0 0;
}
.comment-list li .meta,
.commentlist li .meta {
  margin-bottom: 0;
}
.comment-list li .avatar,
.commentlist li .avatar {
  border-radius: 100%;
}
.comment-list li .description,
.commentlist li .description {
  font-style: italic;
}
.comment-list li .comment-text,
.commentlist li .comment-text {
  padding: 0 1.5em;
}
.comment-list > li:not(:first-child),
.commentlist > li:not(:first-child) {
  border-top: 1px dashed #ddd;
}
#comments .comment-respond {
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  padding: 15px 25px 0;
}
#comments .comment-respond .comment-reply-title {
  font-size: 1.2em;
}
#comments .comment-respond small {
  font-size: 0.6em;
  margin-left: 10px;
  text-transform: uppercase;
}
.footer-wrapper {
  position: relative;
  width: 100%;
}
.footer {
  padding: 30px 0 0;
}
.footer-1 {
  background-color: #fff;
}
.footer-2 {
  background-color: #777;
}
.footer-1,
.footer-2 {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.footer-secondary {
  padding: 7.5px 0;
}
.absolute-footer,
html {
  background-color: #5b5b5b;
}
.footer ul {
  margin: 0;
}
.absolute-footer {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9em;
  padding: 10px 0 15px;
}
.absolute-footer.dark {
  color: hsla(0, 0%, 100%, 0.5);
}
.absolute-footer.fixed {
  background-color: transparent;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 7.5px 15px;
  right: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  z-index: 101;
}
.absolute-footer ul {
  border-bottom: 1px solid #ddd;
  opacity: 0.8;
  padding-bottom: 5px;
}
.absolute-footer.dark ul {
  border-color: hsla(0, 0%, 100%, 0.08);
}
.absolute-footer.text-center ul {
  border: 0;
  padding-top: 5px;
}
.reveal-footer {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
}
.reveal-footer + .footer-wrapper {
  bottom: 0;
  position: fixed;
  z-index: 0;
}
.back-to-top {
  bottom: 20px;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  right: 20px;
  transform: translateY(30%);
}
.back-to-top.left {
  left: 20px;
  right: unset;
}
.back-to-top.active {
  opacity: 1;
  pointer-events: inherit;
  transform: translateY(0);
}
.instagram-image-container {
  display: block;
  padding-bottom: 100%;
}
.instagram-image-container a > img {
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
.logo {
  line-height: 1;
  margin: 0;
}
.logo-tagline {
  font-size: 0.9em;
  font-style: italic;
  line-height: 1;
  margin: 0.85em 0;
}
.logo a {
  color: #446084;
  display: block;
  font-size: 32px;
  font-weight: bolder;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
}
.logo img {
  display: block;
  width: auto;
}
.header-logo-dark,
.header-logo-sticky,
.nav-dark .header-logo,
.sticky .dark .header-logo-dark,
.sticky .has-sticky-logo .header-logo {
  display: none !important;
}
.nav-dark .header-logo-dark,
.stuck .header-logo-sticky {
  display: block !important;
}
.stuck .header-logo-sticky + img,
.stuck .header-logo-sticky + img + img {
  display: none !important;
}
.nav-dark .logo a {
  color: #fff;
}
.logo-left .logo {
  margin-left: 0;
  margin-right: 30px;
}
.logo-center .flex-left {
  flex: 1 1 0;
  order: 1;
}
.logo-center .logo {
  margin: 0 30px;
  order: 2;
  text-align: center;
}
.logo-center .logo img {
  margin: 0 auto;
}
.logo-center .flex-right {
  flex: 1 1 0;
  order: 3;
}
@media screen and (max-width: 849px) {
  .header-inner .nav {
    flex-wrap: nowrap;
  }
  .medium-logo-left .flex-left {
    flex: 0;
    order: -1;
  }
  .medium-logo-left .logo {
    flex: 1;
  }
  .medium-logo-center .flex-left {
    flex: 1 1 0;
    order: 1;
  }
  .medium-logo-center .logo {
    margin: 0 15px;
    order: 2;
    text-align: center;
  }
  .medium-logo-center .logo img {
    margin: 0 auto;
  }
  .medium-logo-center .flex-right {
    flex: 1 1 0;
    order: 3;
  }
}
.map-height {
  height: 400px;
}
.map-inner {
  background-color: #fff;
  background-color: hsla(0, 0%, 100%, 0.9);
  padding: 20px;
}
.message-box {
  background-color: #f1f1f1;
  padding-left: 30px;
  padding-right: 30px;
}
.message-box.dark {
  background-color: #333;
}
.message-box .col,
.message-box .col-inner,
.message-box .row {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.next-prev-thumbs li {
  display: inline-block;
  margin: 0 1px !important;
  position: relative;
}
.next-prev-thumbs li .button {
  margin-bottom: 0;
}
.next-prev-thumbs .nav-dropdown {
  min-width: 90px;
  padding: 2px;
  width: 90px;
}
.page-title {
  position: relative;
}
.page-title .widget {
  margin: 0 0.3em;
  vertical-align: middle;
}
.page-title-bg {
  overflow: hidden;
}
.title-bg {
  transition: opacity 0.6s;
}
.title-overlay {
  background-color: rgba(0, 0, 0, 0.25);
}
.page-title-inner {
  min-height: 60px;
  padding-top: 20px;
  position: relative;
}
.page-title-inner button,
.page-title-inner form,
.page-title-inner p,
.page-title-inner select,
.page-title-inner ul {
  margin-bottom: 0;
  margin-top: 0;
}
.normal-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
}
.normal-title .page-title-inner {
  padding-bottom: 15px;
  padding-top: 15px;
}
.featured-title {
  background-color: #446084;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.featured-title .page-title-inner {
  padding-bottom: 20px;
}
.featured-title .page-title-bg {
  box-shadow: inset 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.featured-title .overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.payment-icons .payment-icon {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: inline-block;
  margin: 3px 3px 0;
  opacity: 0.6;
  padding: 3px 5px 5px;
  transition: opacity 0.3s;
}
.payment-icons .payment-icon svg {
  height: 20px;
  vertical-align: middle;
  width: auto !important;
}
.payment-icons .payment-icon:hover {
  opacity: 1;
}
.dark .payment-icons .payment-icon {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.dark .payment-icons .payment-icon svg {
  fill: #fff;
}
.pricing-table {
  background-color: #fff;
  border: 1px solid #ececec;
  overflow: hidden;
  padding-bottom: 15px;
}
.dark .pricing-table {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.pricing-table .title {
  border-bottom: 1px solid #ececec;
  padding: 0.5em 15%;
}
.dark .pricing-table .title {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.pricing-table .price {
  color: #000;
  font-weight: 300;
  padding: 0.6em 15% 0;
}
.pricing-table .description {
  padding: 15px 15px 0;
}
.pricing-table .items {
  padding: 10px 15%;
}
.pricing-table .is-disabled {
  opacity: 0.6;
  text-decoration: line-through;
}
.pricing-table .items .button:last-child {
  margin: 30px 0 0;
}
.pricing-table .bullet-more-info {
  font-size: 14px;
  margin-left: 10px;
}
.pricing-table .items > div {
  border-bottom: 1px solid #ececec;
  padding: 7.5px 0;
}
.dark .pricing-table .items > div {
  border-color: hsla(0, 0%, 100%, 0.08);
}
.dark .pricing-table {
  background-color: #333;
}
.pricing-table .title {
  background-color: rgba(0, 0, 0, 0.02);
}
.featured-table {
  border-color: #446084;
  border-width: 2px;
  margin-top: -15px;
}
.featured-table .title {
  background-color: #446084;
  border-color: #446084;
  color: #fff;
}
.scroll-to {
  width: 100%;
}
.scroll-to-bullets {
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.scroll-to-bullets a {
  background-color: #446084;
  border: 3px solid transparent;
  border-radius: 30px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  display: block;
  height: 15px;
  margin: 10px 15px;
  transition: all 0.3s;
  width: 15px;
}
.scroll-to-bullets a.active,
.scroll-to-bullets a:hover {
  background-color: #fff;
  opacity: 1;
}
.scroll-to-bullets a.active {
  border-color: #446084;
}
.sidebar-menu .search-form {
  display: block !important;
}
.searchform-wrapper form {
  margin-bottom: 0;
}
.mobile-nav > .search-form,
.sidebar-menu .search-form {
  padding: 5px 0;
  width: 100%;
}
.form-flat .search-form-categories {
  padding-right: 5px;
}
.searchform-wrapper:not(.form-flat) .submit-button,
.widget_search .submit-button {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.searchform {
  position: relative;
}
.searchform .button.icon {
  margin: 0;
}
.searchform .button.icon i {
  font-size: 1.2em;
}
.searchform-wrapper {
  width: 100%;
}
.searchform-wrapper.form-flat .submit-button.loading .icon-search {
  opacity: 0;
}
.searchform-wrapper.form-flat .submit-button.loading:after {
  border-color: currentColor;
}
.searchform-wrapper.form-flat .flex-col:last-of-type {
  margin-left: -2.9em;
}
.searchform-wrapper .autocomplete-suggestions {
  -webkit-overflow-scrolling: touch;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-y: auto;
}
.header .search-form .autocomplete-suggestions,
.header-block .autocomplete-suggestions {
  max-height: 80vh;
}
.col .live-search-results,
.header .search-form .live-search-results,
.header-block .live-search-results {
  background-color: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #111;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 105%;
}
.header li .html .live-search-results {
  background-color: transparent;
  box-shadow: none;
  position: relative;
  top: 0;
}
.autocomplete-suggestion {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: default;
  display: flex;
  padding: 10px 0.75em;
}
.autocomplete-suggestion .search-name {
  flex: 1;
}
.autocomplete-suggestion img {
  border-radius: 99px;
  display: inline-block;
  height: 40px;
  margin-right: 10px;
  width: 40px;
}
.autocomplete-suggestion img + .search-name {
  margin-top: -0.15em;
  padding-left: 0.5em;
}
.autocomplete-suggestion .search-price {
  font-size: 0.8em;
  margin-left: 5px;
  padding-top: 3px;
}
.autocomplete-suggestion:last-child {
  border-bottom: none;
}
.autocomplete-selected {
  background-color: rgba(0, 0, 0, 0.05);
}
#search-lightbox {
  font-size: 1.5em;
  margin: 0 auto;
  max-width: 600px;
}
.section-title-container {
  margin-bottom: 1.5em;
}
.banner + .section-title-container,
.row-collapse + .section-title-container,
.slider-wrapper + .section-title-container {
  margin-top: 30px;
}
.section-title {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.section-title i {
  margin-right: 0.5em;
  opacity: 0.6;
}
.section-title i.icon-angle-right {
  margin-left: 10px;
  margin-right: 0;
}
.section-title span {
  text-transform: uppercase;
}
.section-title small {
  font-size: 1em;
  font-weight: 400;
  opacity: 0.7;
  padding-left: 15px;
  text-transform: none;
}
.section-title b {
  background-color: currentColor;
  display: block;
  flex: 1;
  height: 2px;
  opacity: 0.1;
}
.section-title a {
  display: block;
  font-size: 0.8em;
  margin-left: auto;
  padding-left: 15px;
}
.section-title-normal {
  border-bottom: 2px solid #ececec;
}
.section-title-normal span {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: -2px;
  margin-right: 15px;
  padding-bottom: 7.5px;
}
.dark .section-title-normal,
.dark .section-title-normal span {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.section-title-normal b {
  display: none;
}
.section-title-center span {
  margin: 0 15px;
}
.section-title-bold-center span,
.section-title-center span {
  text-align: center;
}
.section-title-bold-center small,
.section-title-center small {
  display: block;
  padding: 0 15px;
}
.section-title-bold span,
.section-title-bold-center span {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
}
.section-title-bold b:first-of-type {
  display: none;
}
.flatsome-cookies {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.14);
  left: 0;
  padding: 15px 30px;
  position: fixed;
  right: 0;
  top: auto;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.35s ease;
  z-index: 999;
}
.flatsome-cookies__inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flatsome-cookies__text {
  flex: 1 1 auto;
  padding-right: 30px;
}
.flatsome-cookies__buttons {
  flex: 0 0 auto;
}
.flatsome-cookies__buttons > a {
  margin-bottom: 0;
  margin-right: 20px;
}
.flatsome-cookies__buttons > a:last-child {
  margin-right: 0;
}
.flatsome-cookies--inactive {
  transform: translate3d(0, 100%, 0);
}
.flatsome-cookies--active {
  transform: none;
}
@media (max-width: 849px) {
  .flatsome-cookies__inner {
    align-items: stretch;
    flex-direction: column;
    font-size: 0.9em;
    text-align: center;
  }
  .flatsome-cookies__buttons {
    margin-top: 15px;
  }
  .flatsome-cookies__text {
    padding-right: 0;
  }
}
.icon-lock:before {
  content: "";
}
.icon-user-o:before {
  content: "";
}
.icon-chat:before,
.icon-line:before {
  content: "";
}
.icon-user:before {
  content: "";
}
.icon-shopping-cart:before {
  content: "";
}
.icon-tumblr:before {
  content: "";
}
.icon-gift:before {
  content: "";
}
.icon-phone:before {
  content: "";
}
.icon-play:before {
  content: "";
}
.icon-menu:before {
  content: "";
}
.icon-equalizer:before {
  content: "";
}
.icon-shopping-basket:before {
  content: "";
}
.icon-shopping-bag:before {
  content: "";
}
.icon-google-plus:before {
  content: "";
}
.icon-heart-o:before {
  content: "";
}
.icon-heart:before {
  content: "";
}
.icon-500px:before {
  content: "";
}
.icon-vk:before {
  content: "";
}
.icon-angle-left:before {
  content: "";
}
.icon-angle-right:before {
  content: "";
}
.icon-angle-up:before {
  content: "";
}
.icon-angle-down:before {
  content: "";
}
.icon-twitter:before {
  content: "";
}
.icon-envelop:before {
  content: "";
}
.icon-tag:before {
  content: "";
}
.icon-star:before {
  content: "";
}
.icon-star-o:before {
  content: "";
}
.icon-facebook:before {
  content: "";
}
.icon-feed:before {
  content: "";
}
.icon-checkmark:before {
  content: "";
}
.icon-plus:before {
  content: "";
}
.icon-instagram:before {
  content: "";
}
.icon-tiktok:before {
  content: "";
}
.icon-pinterest:before {
  content: "";
}
.icon-search:before {
  content: "";
}
.icon-skype:before {
  content: "";
}
.icon-dribbble:before {
  content: "";
}
.icon-certificate:before {
  content: "";
}
.icon-expand:before {
  content: "";
}
.icon-linkedin:before {
  content: "";
}
.icon-map-pin-fill:before {
  content: "";
}
.icon-pen-alt-fill:before {
  content: "";
}
.icon-youtube:before {
  content: "";
}
.icon-flickr:before {
  content: "";
}
.icon-clock:before {
  content: "";
}
.icon-snapchat:before {
  content: "";
}
.icon-whatsapp:before {
  content: "";
}
.icon-telegram:before {
  content: "";
}
.icon-twitch:before {
  content: "";
}
.icon-discord:before {
  content: "";
}
.ux-shape-divider {
  --divider-top-width: 100%;
  --divider-width: 100%;
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.ux-shape-divider svg {
  display: block;
  height: 150px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
.ux-shape-divider--top {
  top: -1px;
  transform: rotate(180deg);
}
.ux-shape-divider--top svg {
  width: calc(var(--divider-top-width) + 2px);
}
.ux-shape-divider--bottom {
  bottom: -1px;
}
.ux-shape-divider--bottom svg {
  width: calc(var(--divider-width) + 2px);
}
.ux-shape-divider--flip svg {
  transform: translateX(-50%) rotateY(180deg);
}
.ux-shape-divider--to-front {
  z-index: 2;
}
.ux-shape-divider .ux-shape-fill {
  fill: #fff;
}
.text-center .social-icons {
  margin-left: auto;
  margin-right: auto;
}
.social-icons {
  color: #999;
  display: inline-block;
  font-size: 0.85em;
  vertical-align: middle;
}
.html .social-icons {
  font-size: 1em;
}
.html .social-icons .button {
  margin-bottom: 0;
}
.social-icons span {
  font-weight: 700;
  padding-right: 10px;
}
.social-icons i {
  min-width: 1em;
}
.dark .social-icons,
.nav-dark .social-icons {
  color: #fff;
}
.dark .social-icons .button.is-outline,
.nav-dark .social-icons .button.is-outline {
  border-color: hsla(0, 0%, 100%, 0.5);
}
.social-button,
.social-icons .button.icon:hover,
.social-icons .button.icon:not(.is-outline) {
  background-color: currentColor !important;
  border-color: currentColor !important;
}
.social-button > i,
.social-button > span,
.social-icons .button.icon:hover > i,
.social-icons .button.icon:hover > span,
.social-icons .button.icon:not(.is-outline) > i,
.social-icons .button.icon:not(.is-outline) > span {
  color: #fff !important;
}
.button.facebook:hover,
.button.facebook:not(.is-outline) {
  color: #3a589d !important;
}
.button.instagram:hover,
.button.instagram:not(.is-outline) {
  color: #3b6994 !important;
}
.button.whatsapp:hover,
.button.whatsapp:not(.is-outline) {
  color: #51cb5a !important;
}
.button.twitter:hover,
.button.twitter:not(.is-outline) {
  color: #2478ba !important;
}
.button.email:hover,
.button.email:not(.is-outline),
.button.tiktok:hover,
.button.tiktok:not(.is-outline) {
  color: #111 !important;
}
.button.phone:hover,
.button.phone:not(.is-outline) {
  color: #51cb5a !important;
}
.button.pinterest:hover,
.button.pinterest:not(.is-outline) {
  color: #cb2320 !important;
}
.button.rss:hover,
.button.rss:not(.is-outline) {
  color: #fc7600 !important;
}
.button.tumblr:hover,
.button.tumblr:not(.is-outline) {
  color: #36455d !important;
}
.button.vk:hover,
.button.vk:not(.is-outline) {
  color: #527498 !important;
}
.button.google-plus:hover,
.button.google-plus:not(.is-outline) {
  color: #dd4e31 !important;
}
.button.linkedin:hover,
.button.linkedin:not(.is-outline) {
  color: #0072b7 !important;
}
.button.youtube:hover,
.button.youtube:not(.is-outline) {
  color: #c33223 !important;
}
.button.flickr:hover,
.button.flickr:not(.is-outline) {
  color: #e5086f !important;
}
.button.snapchat:hover,
.button.snapchat:not(.is-outline) {
  color: #fff600 !important;
}
.button.snapchat:hover i,
.button.snapchat:not(.is-outline) i {
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.button.px500:hover,
.button.px500:not(.is-outline) {
  color: #0099e5 !important;
}
.button.telegram:hover,
.button.telegram:not(.is-outline) {
  color: #54a9ea !important;
}
.button.twitch:hover,
.button.twitch:not(.is-outline) {
  color: #9146fe !important;
}
.button.discord:hover,
.button.discord:not(.is-outline) {
  color: #7189d9 !important;
}
.woocommerce-product-rating {
  position: relative;
  visibility: visible;
}
.star-rating {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  font-family: fl-icons !important;
  font-size: 16px;
  font-weight: 400 !important;
  height: 1em;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0.5em 0;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 5.06em;
}
.star-rating:before,
.woocommerce-page .star-rating:before {
  color: #d26e4b;
  content: "";
  float: left;
  left: 0;
  position: absolute;
  top: 0;
}
.star-rating span {
  float: left;
  font-family: fl-icons !important;
  font-weight: 400 !important;
  height: 10px;
  left: 0;
  overflow: hidden;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
  width: 10px;
}
.star-rating span:before {
  color: #d26e4b;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
.star-rating--inline {
  display: inline-block;
  height: 18px;
  vertical-align: middle;
}
.woocommerce-review-link {
  font-size: 90%;
}
li.wc-layered-nav-rating {
  font-size: 0.8em;
}
li.wc-layered-nav-rating .star-rating {
  display: inline-block;
  margin-bottom: 0;
}
.stars a {
  color: transparent !important;
  display: inline-block;
  font-family: fl-icons;
  font-size: 16px;
  margin-left: -10px;
  overflow: hidden;
}
.stars a + a {
  border-left: 1px solid #ececec;
  margin-left: 0.3em;
}
.stars a.active:after,
.stars a:hover:after {
  color: #d26e4b;
}
.stars a:after {
  color: #ddd;
  content: "";
}
.stars a.star-2:after {
  content: "";
}
.stars a.star-3:after {
  content: "";
}
.stars a.star-4:after {
  content: "";
}
.stars a.star-5:after {
  content: "";
}
