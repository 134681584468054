.login-sidebar {
  background: url("../../../public/assets/images/bg.png");
}

.login-sidebar {
  height: 100vh;
  width: 50%;
  color: #fff;
  flex-shrink: 0;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

.login-sidebar img:nth-child(2) {
  height: 10px;
}

.title {
  color: #fff;
  font-size: 18px;
}

.forgot-pass-link {
  @apply flex items-center justify-between;
}

.forgot-pass-link a {
  @apply text-sm text-blue-800 hover:underline focus:text-blue-800 cursor-pointer;
}

.img-home {
  height: 56%;
  margin-left: auto;
}
.img-home > img {
  width: 900px;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
